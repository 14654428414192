import {
  ArrowTrendingDownIcon,
  BuildingLibraryIcon,
  CalculatorIcon,
  CurrencyEuroIcon,
  DocumentTextIcon,
  ClipboardDocumentCheckIcon,
  PresentationChartBarIcon,
  MapIcon
} from '@heroicons/react/24/solid';
import classNames from 'classnames';

import { PaddedComponent } from '@/components/PaddedComponent';

const services = [
  {
    icon: MapIcon,
    title: 'Zona Imobiliară',
    subtitle:
      'Introduceți orice adresă, iar noi vă spunem în ce zona imobiliară e încadrată, conform celor mai recente studii de piață.'
  },
  {
    icon: CurrencyEuroIcon,
    title: 'Valoarea imobilului',
    subtitle: 'Aflați valoarea minimă totală pentru un întreg grup de imobile, calculată pe baza celor mai recente studii de piață.'
  },
  {
    icon: CalculatorIcon,
    title: 'Taxe notariale',
    subtitle: 'Calculați toate taxele notariale aferente procedurilor și obțineți un sumar al acestora.'
  },
  {
    icon: PresentationChartBarIcon,
    title: 'Onorarii notariale fixe',
    subtitle:
      'Personalizați direct din aplicație lista onorariilor stabilite în sumă fixă sau în mod liber între notarul public și solicitant.'
  },
  {
    icon: DocumentTextIcon,
    title: 'Numere → Litere',
    subtitle:
      'Convertiți și copiați automat valorile calculate împreună cu descrierea în litere. (ex: 623 (șasesutedouăzecișitreilei).'
  },
  {
    icon: ClipboardDocumentCheckIcon,
    title: 'Justificări',
    subtitle:
      'Vedeți pas cu pas formulele matematice, datele și modul în care au fost făcute toate calculele în aplicație.'
  }
];

export interface Props {}

export const ServicesSection: React.FC<Props> = () => {
  return (
    <PaddedComponent
      as="section"
      id="services"
      className="relative border-b border-solid border-inherit mb-36 py-16 md:py-20"
    >
      <h1 className="text-2xl text-center mb-5 md:text-4xl">Ce puteți face cu Notario?</h1>

      <div className="grid grid-cols-1 gap-12 justify-center pt-16 md:grid-cols-2 md:gap-24 md:pt-28">
        {services.map((service) => (
          <ServiceCard key={service.title} {...service} className="mx-auto my-0" />
        ))}
      </div>

      <div className="text-xs md:text-base absolute inset-x-0 bottom-0 translate-y-1/2 flex justify-center">
        <span className="px-5 md:px-12 text-center text-gray-400 bg-white ">Și multe altele</span>
      </div>
    </PaddedComponent>
  );
};

interface ServiceCardProps {
  icon: React.ElementType;
  title: string;
  subtitle: string;
  className?: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ icon: Icon, title, subtitle, className }) => {
  return (
    <div
      className={classNames(
        'relative p-5 shadow-landing-service rounded-2xl max-w-[375px] sm:max-w-[50%] md:max-w-full',
        className
      )}
    >
      <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full shadow-landing-service p-4 -mt-5 bg-white">
        <div className="absolute -bottom-1/2 -right-1/2 bg-white h-full w-[200%]" />

        <Icon className="relative w-9 h-9 text-main-light z-10" />
      </div>

      <div className="relative z-10">
        <div className="text-sm text-center font-medium my-5 md:text-2xl ">{title}</div>

        <div className="text-xs text-center md:text-lg ">{subtitle}</div>
      </div>
    </div>
  );
};
