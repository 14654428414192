import { api } from '@/util/networking';

export type EnrollmentResponse = void;

export interface EnrollmentRequestBody {
  name: string;
  email: string;
}

export const subscribeUser = (options: EnrollmentRequestBody) => {
  return api.post<EnrollmentResponse, EnrollmentRequestBody>('/v1/monitoring/subscribe/', options);
};

export const enrollmentAccess = {
  subscribeUser
};
