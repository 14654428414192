import { Combobox as HeadlessUiCombobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';

import { Item, SelectProps, getDisplayName } from './common';

export type InputAppearance = 'accent';

export interface Props<T> extends SelectProps<T> {
  query?: string;
  inputAppearance?: InputAppearance;
  onQueryChange: (query: string) => void;
}

export const Combobox = <K extends string | number, T extends Item<K>>({
  id,
  value,
  label,
  placeholder,
  options,
  multiple,
  disabled,
  onChange,
  query,
  className,
  onQueryChange,
  isLoading,
  inputAppearance
}: Props<T>) => {
  return (
    // The typecheck issue is caused by the 'multiple' prop
    // @ts-ignore
    <HeadlessUiCombobox as="div" {...{ value, onChange, multiple, disabled, className }}>
      {({ open }) => (
        <>
          {label && (
            <HeadlessUiCombobox.Label className="block text-sm font-medium text-gray-500 mb-1">
              {label}
            </HeadlessUiCombobox.Label>
          )}

          <div className="relative">
            <HeadlessUiCombobox.Input
              className={classNames(
                'w-full rounded-md borderbg-white py-2 pl-3 pr-10 shadow-sm focus:border-main focus:outline-none focus:ring-1 focus:ring-main sm:text-sm',
                disabled ? 'border-gray-200 text-gray-300 placeholder:text-gray-300' : 'border-gray-300',
                inputAppearance === 'accent' && 'text-main font-semibold'
              )}
              displayValue={(option: T | T[]) => getDisplayName(option, multiple) ?? ''}
              onChange={(event) => onQueryChange(event.target.value)}
              {...{ id, placeholder }}
            />

            <HeadlessUiCombobox.Button
              className={classNames(
                'absolute min-h-[2.375rem] inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none',
                disabled ? 'text-gray-300' : 'text-gray-400'
              )}
            >
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </HeadlessUiCombobox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessUiCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {isLoading && <div className="py-2 pl-3 pr-9">Căutam...</div>}

                {!options.length && !isLoading && !query && <div className="py-2 pl-3 pr-9">Începe să scrii</div>}

                {options.map((option) => (
                  <HeadlessUiCombobox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9 hover:cursor-pointer',
                        active ? 'bg-main-light' : 'text-gray-900'
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={classNames('block', selected && 'font-semibold')}>{option.name}</span>

                        {selected && (
                          <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </HeadlessUiCombobox.Option>
                ))}
              </HeadlessUiCombobox.Options>
            </Transition>
          </div>
        </>
      )}
    </HeadlessUiCombobox>
  );
};
