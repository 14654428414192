import classNames from 'classnames';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
}

export const TextArea = ({ label, className, ...rest }: Props) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      {label && (
        <label htmlFor={rest.id} className="block text-sm font-medium text-gray-500 mb-1">
          {label}
        </label>
      )}

      <textarea
        className={classNames(
          'block w-full h-full flex-1 min-h-[2.375rem] py-2 px-3 rounded-md border-gray-300 resize-none shadow-sm focus:border-main focus:ring-main sm:text-sm'
        )}
        {...rest}
      />
    </div>
  );
};
