import {API} from "@/config";

export const links = [
  {
    href: '#painpoints',
    name: 'Beneficii'
  },
  {
    href: '#services',
    name: 'Funcționalități'
  },
  { href: '#contact', name: 'Contact' }
];

export const routes = {
  login: '/login',
  register: '/',
  app: '/home',
  profile: '/profil',
}

export const CONTACT_EMAIL_ADDRESS = 'contact@notario.ro';
export const CONTACT_PHONE_NUMBER = '+40 773 800 536';

export const FEEDBACK_FORM_URL = 'https://forms.gle/HMbVTuqUJ5XT81G2A';
export const TC_URL = `${API.replace('api', 'static/docs/t&c.pdf')}`;

export const STRIPE_URL = 'https://buy.stripe.com/test_00gg1TerW2Jy6godQV';


export const openExternalSiteInNewTab = () => {
    const externalUrl = `${FEEDBACK_FORM_URL}`;
    window.open(externalUrl, '_blank'); 
  };

  export const openTC= () => {
    window.open(TC_URL, '_blank');
  };

  export const redirectToStripe = (email: string) => {
    const externalUrl = `${STRIPE_URL}?prefilled_email=${email}`;
    window.open(externalUrl, '_blank'); 
  };