import classNames from 'classnames';
import React from 'react';

export type Variant = 'white' | 'green' | 'lightGreen';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: Variant;
  slim?: boolean;
}

const style: Record<Variant, string> = {
  white:
    'flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2',
  green:
    'flex items-center justify-center rounded-md border border-transparent bg-main px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-main-dark focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 disabled:bg-gray-300',
  lightGreen:
    'flex items-center justify-center rounded-md border border-transparent bg-[#DBE7E0] px-4 py-2 text-base font-medium text-[#527A62] shadow-sm focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 disabled:bg-gray-300',
};

export const Button = ({ variant = 'green', className, children, slim, ...rest }: React.PropsWithChildren<Props>) => {
  return (
    <button
      type="button"
      className={classNames(
        'transition-colors',
        style[variant],
        {
          'text-sm': slim
        },
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};
