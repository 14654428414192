import { ComputeFeesValueResponse } from '@/access';

export interface Props {
  value: ComputeFeesValueResponse;
}

export const FeeJustificationTable: React.FC<Props> = ({ value }) => {
  return (
    <table className="table w-full text-[1.3vw]">
      <tr>
        <th>#</th>

        <th>Tip</th>

        <th>Valoare taxă</th>

        <th>Pondere</th>

        <th>Valoarea imobilului</th>

        <th>Justificare</th>

        <th>Total</th>
      </tr>

      {value.justification.map(({ fee, percentage, value, calculation, total, fixed_fee, ...rest }, index, arr) => (
        <tr key={index}>
          <td>{index + 1}</td>

          <td>{fee}</td>

          <td>{fixed_fee}</td>

          <td>{percentage}</td>

          <td>{value}</td>

          <td>{calculation}</td>

          <td>{total}</td>
        </tr>
      ))}
    </table>
  );
};
