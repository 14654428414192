import useSWR from 'swr';

import { placesAccess } from '@/access';

export const useLoadCounties = () => {
  return useSWR('getCountiesList', () => placesAccess.getCountiesList(), {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
