import { Listbox as HeadlessUiListbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';

import { Item, SelectProps, getDisplayName } from './common';

export interface Props<T> extends SelectProps<T> {}

export const Listbox = <K extends string | number, T extends Item<K>>({
  id,
  value,
  label,
  placeholder,
  options,
  multiple,
  name,
  disabled,
  onChange,
  className
}: Props<T>) => {
  return (
    <HeadlessUiListbox as="div" {...{ value, onChange, multiple, name, disabled, className }}>
      {({ open }) => (
        <>
          {label && (
            <HeadlessUiListbox.Label className="block text-sm font-medium text-gray-500 mb-1">
              {label}
            </HeadlessUiListbox.Label>
          )}

          <div className="relative">
            <HeadlessUiListbox.Button
              {...{ id }}
              className={classNames(
                'relative min-h-[2.375rem] w-full cursor-default rounded-md border bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-main focus:outline-none focus:ring-1 focus:ring-main sm:text-sm',
                disabled ? 'border-gray-200 text-gray-300' : 'border-gray-300'
              )}
            >
              <span className="block truncate">
                {getDisplayName(value, multiple) ?? (
                  <span className={classNames(!disabled && 'text-gray-500')}>{placeholder}</span>
                )}
              </span>

              <span
                className={classNames(
                  'absolute inset-y-0 right-0 flex items-center pr-2 hover:cursor-pointer',
                  disabled ? 'text-gray-300' : 'text-gray-400'
                )}
              >
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </HeadlessUiListbox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessUiListbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((item) => (
                  <HeadlessUiListbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-main-light' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9 hover:cursor-pointer'
                      )
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block')}>
                          {item.name}
                        </span>

                        {selected && (
                          <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </HeadlessUiListbox.Option>
                ))}
              </HeadlessUiListbox.Options>
            </Transition>
          </div>
        </>
      )}
    </HeadlessUiListbox>
  );
};
