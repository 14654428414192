import { Dialog, Props as DialogProps } from '@/components/Dialog';
import { EvaluationJustificationTable } from '@/components/EvaluationJustificationTable';

import { ComputeEstateValueResponse } from '@/access';

export interface Props extends DialogProps {
  value: ComputeEstateValueResponse;
}

export const EvaluationJustificationDialog: React.FC<Props> = ({ value, ...props }) => {
  return (
    <Dialog {...props} className="flex py-12 md:w-[90vw]">
      <div className="overflow-scroll flex-1">
        <EvaluationJustificationTable {...{ value }} />
      </div>
    </Dialog>
  );
};
