import { ComputeEstateValueResponse } from '@/access';

export interface Props {
  value: ComputeEstateValueResponse;
}

export const EvaluationJustificationTable: React.FC<Props> = ({ value }) => {
  return (
    <table className="table w-full text-[1.3vw]">
      <tr>
        <th>#</th>

        <th>Tip</th>

        <th>Criteriu din studiul de piata</th>

        <th>Suprafata</th>

        <th>Valoarea din studiul de piata</th>

        <th>Cota</th>

        <th>Justificare</th>

        <th>Total</th>
      </tr>

      {value.category_taxes.map(({ justification, tax_value, surface_quota_justification, ...category }, index) => {
        return [
          ...justification.map(
            ({ applied_criteria_name, input_surface, study_tax_value, total_justification }, key, arr) => (
              <tr key={`${index}-${key}`}>
                {key === 0 && <td rowSpan={arr.length + 1}>{index + 1}</td>}

                <td>{category.category_name}</td>

                <td>{applied_criteria_name}</td>

                <td>{input_surface}</td>

                <td>{study_tax_value}</td>

                <td></td>

                <td>{total_justification}</td>

                <td>{total_justification.split('=')[1]}</td>
              </tr>
            )
          ),
          <tr key={`${index}-total}`}>
            <td></td>

            <td></td>

            <td></td>

            <td></td>

            <td>
              {surface_quota_justification &&
                `${surface_quota_justification.quota_value}/${surface_quota_justification.out_of}`}
            </td>

            <td>{surface_quota_justification && surface_quota_justification.total_justification}</td>

            <td>{tax_value}</td>
          </tr>
        ];
      })}
    </table>
  );
};
