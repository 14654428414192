import classNames from 'classnames';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
  variant?: Variant;
}

type Variant = 'green' | 'red';

const style: Record<Variant, string> = {
  green: 'text-main group-hover:text-main-dark',
  red: 'text-red-600 group-hover:text-red-800'
};

const getStyle =
  (variant: Variant) =>
  (disabled = false) => {
    const style: Record<Variant, Record<string, boolean>> = {
      green: {
        'text-main group-hover:text-main-dark': !disabled,
        'text-gray-300': disabled
      },
      red: { 'text-red-600 group-hover:text-red-800': !disabled, 'text-gray-300': disabled }
    };

    return style[variant];
  };

export const FeedbackButton: React.FC<Props> = ({ icon: Component, className, variant = 'green', ...rest }) => (
  <button
    {...rest}
    className={classNames(
      'group',
      {
        'hover:cursor-pointer': !rest.disabled
      },
      className
    )}
  >
    <Component
      className={classNames(
        'w-6 h-6',
        { 'group-hover:animate-bounce': !rest.disabled },
        getStyle(variant)(rest.disabled)
      )}
    />
  </button>
);
