import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import '@/util/monitoring/sentry';

import { ErrorBoundary } from '@/components/ErrorBoundary';

import App from '@/App';
import { LandingPage } from '@/pages/LandingPage';

import './index.css';
import { LoginPage } from './pages/Login/LoginPage';
import { RegisterPage } from '@/pages/Register/RegisterPage';
import { ProfilePage } from './pages/ProfilePage';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <Helmet titleTemplate="%s - Notario" defaultTitle="Notario" />

          <Routes>
            <Route path="/home/*" element={<App />} />

            {/* <Route path="/app/*" element={<App />} /> */}
      
            <Route path='/login' element={<LoginPage />} />
            <Route path='/' element={<RegisterPage />} />
            <Route path="/prezentare" element={<LandingPage />} />


            {/* <Route path='/login' element={<LoginPage />} /> */}

            <Route path="*" element={<Navigate to="/home" replace />} />
            <Route path="/app" element={<Navigate to="/home" replace />} />

          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
