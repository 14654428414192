import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { NavigationItem as NavigationItemType } from '@/components/Layout';

import { SidebarContainer } from './SidebarContainer';
import { AuthResponse } from '@/access';
import { useLocalStorage } from 'react-use';
import { MouseEventHandler } from 'react';
import { openExternalSiteInNewTab } from '@/constants/constants';

export interface Props {
  item: NavigationItemType;
  isActive?: boolean;
  onItemClick?: () => void;
  clearUserData?: () => void;
}

export const NavigationItem = ({ item, onItemClick, clearUserData }: Props) => {
  const { isOpen } = SidebarContainer.useContainer();

  return (
    <NavLink
      to={item.to}
      className={({ isActive }) =>
        classNames(
          'relative z-10 flex items-center py-4 pr-4 pl-14 text-sm hover:bg-main-dark md:px-4 md:rounded-r-md md:flex md:transition-colors',
          {
            'bg-main-dark md:rounded-r-md': isActive && !item.redirect
          }
        )
      }
      state={{ key: Date.now() }}
      {...(item.end && { end: item.end })}
      {...(onItemClick && { onClick: onItemClick }) }
      {...(item.redirect && { onClick: openExternalSiteInNewTab })}
      {...(item.logout && { onClick: clearUserData })}
    >
      <item.icon className={classNames('mr-4 h-6 w-6 min-w-[1.5rem]', !isOpen && 'mr-0')} aria-hidden="true" />

      {/* TODO: Fix this when hidden */}
      <span className={classNames('transition-opacity delay-150 duration-150 opacity-100', !isOpen && 'md:opacity-0')}>
        {item.name}
      </span>
    </NavLink>
  );
};
