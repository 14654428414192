import classNames from 'classnames';

import { withValidation } from '@/components/withValidation';

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  invalid?: boolean;
}

export const Input = ({ label, className, invalid, ...rest }: Props) => {
  return (
    <div {...{ className }}>
      {label && (
        <label htmlFor={rest.id} className="block text-sm font-medium text-gray-500 mb-1">
          {label}
        </label>
      )}

      <input
        className={classNames(
          'block w-full min-h-[2.375rem] py-2 px-3 rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main placeholder:text-gray-400',
          { 'border-red-500 ring-1 ring-red-500 text-red-500': invalid }
        )}
        onWheel={(event) => (event.target as HTMLElement).blur()}
        {...rest}
      />
    </div>
  );
};

export const InputWithValidation = withValidation(Input);
