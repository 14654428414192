import { Layout } from '@/components/Layout/Layout';
import { Notification } from '@/components/Notification';
import ReactGA from 'react-ga4'
import {REACT_GA_CODE} from "@/config";

if (REACT_GA_CODE) {
    ReactGA.initialize(REACT_GA_CODE)
}

export interface Props {}

export const App: React.FC<Props> = () => {

  return (
    <Notification.Container.Provider>
      <Notification.Portal />

      <Layout />
    </Notification.Container.Provider>
  );
};

export default App;
