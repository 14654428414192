import { PaddedComponent } from '@/components/PaddedComponent';

import { EnrollmentForm } from '@/pages/LandingPage/EnrollmentForm';

import { ReactComponent as EnrollmentAsset } from './enrollment-section.svg';

export interface Props {}

export const EnrollmentSection: React.FC<Props> = () => {
  return (
    <PaddedComponent
      as="section"
      className="relative flex flex-col py-8 md:py-20 md:flex-row bg-gradient-to-r from-main-light from-32% to-[#c8dfd1] to-88%"
    >
      <EnrollmentAsset className="hidden md:inline-block absolute left-0" />

      <div className="relative mb-5 text-center md:mb-0 md:mr-5 md:w-1/2 md:text-left md:z-10">
        <h1 className="text-lg md:text-3xl font-semibold mb-3">Nu v-am convins încă?</h1>

        <p className="text-sm md:text-xl mb-7">
          Puteți aplica aici pentru a primi cont personalizat
        </p>

        <p className="text-xs text-gray-500 md:text-base">
          Aplicația este în permanentă dezvoltare. Adăugăm în continuare funcționalități noi și îmbunătățiri pentru a
          face cât mai ușor procesul de calculare al valorilor imobiliare și al taxelor notariale
        </p>
      </div>

      <div className="flex justify-center items-center md:w-1/2">
        <EnrollmentForm id="enrollment-section" />
      </div>
    </PaddedComponent>
  );
};
