import { FormSpy, useField  } from 'react-final-form';
// import { useDebounce } from 'react-use';
import React from 'react';

export interface Props {
  debounce?: number;
}

export const SubmitOnValuesChange: React.FC<Props> = ({ debounce = 1000 }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }) => {
        React.useEffect(() => {
          const timer = setTimeout(() => {
            form.submit();
          }, debounce);

          return () => clearTimeout(timer);
        }, [values, form.submit]);

        return <></>;
      }}
    </FormSpy>
  );
};
