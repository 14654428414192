import { api } from '@/util/networking';

export interface ComputeEstateValueResponse {
  category_taxes: {
    category_name: string;
    tax_value: number;
    justification: {
      applied_criteria_name: string;
      input_surface: string;
      study_tax_value: string;
      total_justification: string;
    }[];
    surface_quota?: { quota_value: number; out_of: number };
    surface_quota_justification?: {
      category_name: string;
      initial_category_price: string;
      quota_value: number;
      out_of: number;
      total_justification: string;
    };
  }[];
  total_price: number;
}

export interface ComputeEstateValueRequestBody {
  area_id: number;
  category_widget_answers: {
    category_id: number;
    widgets: {
      widget_id: number;
      value: number | string;
    }[];
  }[];
}

export const computeEstateValue = (options: ComputeEstateValueRequestBody) => {
  return api.post<ComputeEstateValueResponse, ComputeEstateValueRequestBody>(
    '/v1/taxes/minimum-value/calculate/',
    options
  );
};

export const taxesAccess = {
  computeEstateValue
};
