import { CheckCircleIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import * as yup from 'yup';

import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';
import { Form } from '@/components/Form';
import { InputWithValidation } from '@/components/Input';

import { enrollmentAccess } from '@/access';

export interface Props {
  id: string;
  className?: string;
}

export const EnrollmentForm: React.FC<Props> = ({ id, className }) => (
  <Form
    {...{ id }}
    schema={yup.object({
      name: yup.string().min(1).max(128).required(),
      email: yup.string().email().min(1).max(254).required()
    })}
    className={classNames('flex flex-col w-full space-y-3', className)}
    onSubmit={async (values) => {
      enrollmentAccess.subscribeUser(values).catch(() => null);
    }}
  >
    {({ id, submitSucceeded }) => (
      <>
        <Form.Field
          as={InputWithValidation}
          id={`${id}-name`}
          name="name"
          type="text"
          placeholder="Introduceți numele"
          className="w-full"
        />

        <Form.Field
          as={InputWithValidation}
          id={`${id}-email`}
          name="email"
          type="text"
          placeholder="Introduceți adresa de e-mail"
          className="w-full"
        />

        <ButtonWithIcon
          icon={submitSucceeded ? CheckCircleIcon : PaperAirplaneIcon}
          type="submit"
          form={id}
          className="w-full"
          disabled={submitSucceeded}
        >
          {submitSucceeded ? 'Trimis' : 'Aplicați'}
        </ButtonWithIcon>
      </>
    )}
  </Form>
);
