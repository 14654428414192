type Part = 'integer' | 'fractional';

type NumericalScale = 1 | 1_000 | 1_000_000 | 1_000_000_000;

interface GrammaticalNumber {
  one: string;
  many: string;
}

type GroupFormatterFn = ({ value, word }: { value: number; word: string }) => string;

interface DecomposedToDigit {
  digit: number;
  position: number;
}

const units: Record<number, string> = {
  0: 'zero',
  1: 'unu',
  2: 'doi',
  3: 'trei',
  4: 'patru',
  5: 'cinci',
  6: 'șase',
  7: 'șapte',
  8: 'opt',
  9: 'nouă'
};

const tens: Record<number, string> = {
  10: 'zece',
  11: 'unsprezece',
  12: 'doisprezece',
  13: 'treisprezece',
  14: 'paisprezece',
  15: 'cincisprezece',
  16: 'șaisprezece',
  17: 'șaptesprezece',
  18: 'optsprezece',
  19: 'nouăsprezece',
  20: 'douăzeci',
  30: 'treizeci',
  40: 'patruzeci',
  50: 'cincizeci',
  60: 'șaizeci',
  70: 'șaptezeci',
  80: 'optzeci',
  90: 'nouăzeci'
};

const unitsAndTens = { ...units, ...tens };

type MonetaryUnit = 'leu' | 'ban';

const monetaryUnitGrammaticalNumber: Record<MonetaryUnit, GrammaticalNumber> = {
  leu: {
    one: 'un leu',
    many: 'lei'
  },
  ban: {
    one: 'un ban',
    many: 'bani'
  }
};

const monetaryUnitFormatterFn =
  (unit: MonetaryUnit): GroupFormatterFn =>
  ({ value, word }) => {
    const gramaticalNumber = monetaryUnitGrammaticalNumber[unit];

    if (value === 0) return `${units[value]} ${gramaticalNumber.many}`;
    if (value === 1) return gramaticalNumber.one;

    const lastTwoDigits = value % 100;

    if (lastTwoDigits > 0 && lastTwoDigits < 20) return `${word} ${gramaticalNumber.many}`;

    return `${word} de ${gramaticalNumber.many}`.trim();
  };

const numericalScaleFormatterFn =
  (unit: Exclude<NumericalScale, 1>): GroupFormatterFn =>
  ({ value, word }) => {
    const gramaticalNumber = numericalScaleGrammaticalNumber[unit];

    if (value === 1) return gramaticalNumber.one;

    const lastTwoDigits = value % 100;

    if (lastTwoDigits > 1 && lastTwoDigits < 20) return `${word} ${gramaticalNumber.many}`;

    let gramatticalGenderNormalisedWord = word;

    if (word.slice(-3) === 'unu') gramatticalGenderNormalisedWord = `${word.slice(0, -3)}una`;
    if (word.slice(-3) === 'doi') gramatticalGenderNormalisedWord = `${word.slice(0, -3)}două`;

    return `${gramatticalGenderNormalisedWord} de ${gramaticalNumber.many}`.trim();
  };

const numericalScaleGrammaticalNumber: Record<Exclude<NumericalScale, 1>, GrammaticalNumber> = {
  1_000: {
    one: 'o mie',
    many: 'mii'
  },
  1_000_000: {
    one: 'un milion',
    many: 'milioane'
  },
  1_000_000_000: {
    one: 'un miliard',
    many: 'miliarde'
  }
};

const numericalScaleGroupFormatter: Record<NumericalScale, GroupFormatterFn> = {
  1: ({ word }) => word,
  1_000: numericalScaleFormatterFn(1_000),
  1_000_000: numericalScaleFormatterFn(1_000_000),
  1_000_000_000: numericalScaleFormatterFn(1_000_000_000)
};

const convertGroupOfAtMostThreeDigits = (input: number, part?: Part) => {
  if (input < 0 && input > 999) throw new Error('Out of range');
  if (!Number.isInteger(input)) throw new Error('Not integer');

  const decomposedNumber = decomposeToDigits(input, part);

  decomposedNumber.reverse();

  const result = decomposedNumber.reduce(
    (acc, element, index, array) => {
      if (element.digit === 0 || acc.stop) return acc;

      let text = '';

      if (element.position === 100) {
        if (element.digit === 1) text = `o sută`;
        if (element.digit === 2) text = `două sute`;
        if (element.digit > 2) text = `${unitsAndTens[element.digit]} sute`;
      }

      if (element.position === 10) {
        const rest = element.digit * 10 + array[index + 1].digit;

        if (rest > 0 && rest < 21) return { text: `${acc.text} ${unitsAndTens[rest]}`, stop: true };

        if (array[index + 1].position === 1 && array[index + 1].digit === 0)
          text = `${unitsAndTens[element.digit * element.position]}`;
        else text = `${unitsAndTens[element.digit * element.position]} și`;
      }

      if (element.position === 1) {
        text = `${unitsAndTens[element.digit * element.position]}`;
      }

      return { text: `${acc.text} ${text}`, stop: false };
    },
    { text: '', stop: false }
  );

  return result.text.trim();
};

const decompose = (input: number, fn: (input: number) => void, step = 10) => {
  let mutable = input;

  while (mutable > 0) {
    const num = mutable % step;

    fn(num);

    mutable = Math.trunc(mutable / step);
  }
};

const decomposeToDigits = (input: number, part?: Part): DecomposedToDigit[] => {
  const array: number[] = [];

  decompose(input, (number) => array.push(number));

  return array.map((digit, power) => ({
    digit,
    position: Math.pow(10, power)
  }));
};

const numberToText = (number: number, part: Part) => {
  if (number === 0 || number === 1) return units[number];

  const array: number[] = [];

  decompose(number, (number) => array.push(number), 1000);

  const result = array.map((value, index) => {
    const numericalScaleItem = Math.pow(1000, index) as NumericalScale;

    const group = { value, word: convertGroupOfAtMostThreeDigits(value, part) };

    return numericalScaleGroupFormatter[numericalScaleItem](group);
  });

  return result.reverse().join(' ').trim();
};

const toText = (amount: number, complete = false) => {
  const integer = Math.trunc(amount);
  const fractional = parseInt(amount.toFixed(2).split('.')[1] ?? 0);

  const integerText = monetaryUnitFormatterFn('leu')({ value: integer, word: numberToText(integer, 'integer') });
  const fractionalText = monetaryUnitFormatterFn('ban')({
    value: fractional,
    word: numberToText(fractional, 'fractional')
  });

  if (complete) return `${integerText} și ${fractionalText}`;

  if (integer === 0 && fractional === 0) return 'zero lei';

  return `${integer === 0 ? '' : integerText} ${
    fractional ? `${integer === 0 ? '' : 'și'} ${fractionalText}` : ''
  }`.trim();
};

export const currencyConverter = { toText };
