import get from 'lodash/get';

export interface Reference {
  ref: string;
}

export type Operator = '>' | '==' | '||' | '&&';

export interface Expression {
  lhs: string | number | Reference | Expression;
  op: Operator;
  rhs: string | number | Reference | Expression;
}

const map = {
  '>': <T = string | number>(lhs: T, rhs: T) => lhs > rhs,
  '==': <T = string | number>(lhs: T, rhs: T) => lhs === rhs,
  '||': <T = string | number>(lhs: T, rhs: T) => lhs || rhs,
  '&&': <T = string | number>(lhs: T, rhs: T) => lhs && rhs
};

const isRef = (reference: string | number | Reference | Expression): reference is Reference =>
  !!(reference as Reference).ref;

export const evaluate = (values: Record<string, unknown>) => (expression: string | number | Reference | Expression) => {
  const e = (expression: string | number | Reference | Expression): string | number | boolean => {
    if (typeof expression === 'string' || typeof expression === 'number') return expression;

    if (isRef(expression)) {
      return get(values, expression.ref) as string | number | boolean;
    }

    const { op, lhs, rhs } = expression;

    return map[op](e(lhs), e(rhs));
  };

  return e(expression);
};
