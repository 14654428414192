import { Listbox, Props as SelectProps } from '@/components/Select/Listbox';

import { CountyListItem } from '@/domain';
import { useLoadCounties } from '@/hooks/useLoadCounties';

export interface Props extends Omit<SelectProps<CountyListItem>, 'options'> {}

export const CountiesLoadableListbox = (props: Props) => {
  const counties = useLoadCounties();

  return <Listbox options={counties.data ?? []} {...props} />;
};
