import useSWR from 'swr';

import { GetStreetsListQuery, placesAccess } from '@/access';

export type Options = GetStreetsListQuery;

export const useLoadStreets = (options: Partial<Options>) => {
  return useSWR(
    options.city_id && options.name ? { ...options, key: 'getStreetsList' } : undefined,
    ({ city_id, name }) => {
      if (!city_id || !name) return;

      return placesAccess.getStreetsList({ city_id, name });
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
};
