import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Page } from '@/components/Page';
import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';
import {
  ArrowLeftOnRectangleIcon,
  ClipboardIcon,
  CreditCardIcon,
  HomeIcon,
  PencilIcon
} from '@heroicons/react/20/solid';
import { useLocalStorage } from 'react-use';
import { AuthResponse, ProfileResponse } from '@/access';
import { useNavigate } from 'react-router-dom';
import { CONTACT_EMAIL_ADDRESS, CONTACT_PHONE_NUMBER, routes } from '@/constants';
import { redirectToStripe } from '@/constants/constants';

export interface Props {
}

export const ProfilePage: React.FC<Props> = () => {

  const navigate = useNavigate();
  const [_, __, removeAccessTokenData] = useLocalStorage<AuthResponse>('access-token');
  const [profileData, setProfileData, removeProfileData] = useLocalStorage<ProfileResponse>('profile-data');
  
  const clearUserData = () => {
    removeProfileData();
    removeAccessTokenData();
    navigate(routes.login);
  }

  const accountFullName =
  profileData !== undefined || null ?
  `${profileData?.first_name} ${profileData?.last_name}` : null;

  return (
    <Page>
      <Helmet>
        <title>Profilul meu</title>
      </Helmet>
      {/*spans color #374151*/}
      <div className="relative m-0 p-0 wrapper-min-height">
      <span className="block text-3xl">Profilul meu</span>
      <span className="block text-lg">Informații despre profilul tău</span>
      <div className="relative flex flex-col max-w-screen-md mx-auto mt-20">
        <div className="flex flex-row rounded-t-2xl bg-[#496C4C] w-full items-center p-5">
          <div
            className="relative flex flex-col color text-white my-3"
            style={{
              fontSize: '25px',
              fontFamily: 'Poppins',
              fontWeight: 400,
              lineHeight: '20px',
            }}>
              Salut,  {`${profileData?.first_name}`}
          </div>
          {/* <div className="relative flex flex-col color text-white"> */}
            {/* <span className="text-lg font-medium">John Doe</span>
            <span className="text-sm font-normal">john.doe@gmail.com</span> */}
          {/* </div> */}
          {/* <ButtonWithIcon icon={PencilIcon} className='ml-auto bg-transparent	rounded p-2 h-9'>
            Editeaza profil
          </ButtonWithIcon> */}
        </div>
        <div className="flex flex-col w-full mt-8 mx-4">
          <span className="text-lg">Date de facturare</span>
          <div className="flex flex-row w-full mt-5">
            <div className="w-2/4">
              <label className="block text-[#6B7280] text-sm">Nume</label>
              <input
                className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500"
                value={`${profileData?.last_name}`}
              />
            </div>
            <div className="w-2/4 ml-8">
              <label className="block text-[#6B7280] text-sm">Prenume</label>
              <input
                className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500"
                value={`${profileData?.first_name}`}
              />
            </div>
          </div>
          <div className="flex flex-row w-full mt-5">
            <div className="w-2/4">
              <label className="block text-[#6B7280] text-sm">Cabinet notarial</label>
              <input
                className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500"
                value={`${profileData?.office_name}`}
              />
            </div>
          </div>
          <div className="flex flex-row w-full mt-5">
            <div className="w-2/4">
              <label className="block text-[#6B7280] text-sm">Telefon</label>
              <input
                className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500"
                value={`${profileData?.phone_number}`}
              />
            </div>
            <div className="w-2/4 ml-8">
              <label className="block text-[#6B7280] text-sm">Email</label>
              <input
                className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500"
                value={`${profileData?.email}`}
              />
            </div>
          </div>
          {/* <div className="flex flex-row items-end	w-full mt-5"> */}
            {/* <div className="w-2/4"> */}
              {/* <label className="block text-[#6B7280] text-sm">Cont bancar</label> */}
              {/* <input */}
                {/* className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500" */}
                {/* value="RO12 1234 5678 9012 3456 7890" */}
              {/* /> */}
            {/* </div> */}
            {/* <div className="w-2/4 ml-8"> */}
              {/*<label className="block text-[#6B7280] text-sm">Oras</label>*/}
              {/* <input */}
                {/* className="border-b border-transparent w-full py-1 focus:outline-none focus:border-blue-500" */}
                {/* value="Platitor TVA (19%)" */}
              {/* /> */}
            {/* </div> */}
          {/* </div> */}
        </div>

        <div className="mt-10 flex flex-row w-full">
          <ButtonWithIcon icon={ArrowLeftOnRectangleIcon} variant='white' className='mr-auto grey-text-color rounded-md	 p-2 h-9'
            onClick={() => clearUserData()}
            >
            Delogheaza-te
          </ButtonWithIcon>
          {/* <ButtonWithIcon icon={CreditCardIcon} variant='lightGreen' className='ml-auto bg-[#DBE7E0] hover:bg-[#DBE7E0] text-[#527A62]	rounded-md	p-2 h-9'
            onClick={() => profileData?.email && redirectToStripe(profileData.email)}
          >
            Plata abonament
          </ButtonWithIcon> */}
          <ButtonWithIcon icon={HomeIcon} className='ml-5 hover:bg-[#527A62] bg-[#527A62] text-white rounded-md p-2 h-9'
            onClick={() => navigate(routes.app)}
            >
            Mergi catre pagina de start
          </ButtonWithIcon>
        </div>
        <div className="mt-10 text-center w-full"
          style={{
            fontSize: '15px',
        }}>
         Dacă întâmpinați probleme tehnice, ne puteți contacta la 
         <a className="text-green-600 text-base font-semibold"
          href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>{` ${CONTACT_EMAIL_ADDRESS} `}</a>
         sau la 
         <a className="text-green-600 text-base font-semibold"
          href={`tel:${CONTACT_PHONE_NUMBER}`}>{` ${CONTACT_PHONE_NUMBER}`}</a>
        </div>
      </div>
      {/* <footer className="absolute bottom-12 max-w-screen-md text-sm text-[#4B5563] translate-x-2/4	right-2/4">All information, software, services, and comments provided on the site are for informational and self-help purposes only and are not intended to be a substitute for professional legal advice. Use of this site is subject to our Terms of Use</footer> */}
      </div>
    </Page>
  );
};
