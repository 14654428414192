import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

// import * as Sentry from '@sentry/react';

export enum Header {
  AcceptLanguage = 'accept-language',
  Authorization = 'authorization'
}

export type HttpPromise<T, C extends HttpRequestConfig = HttpRequestConfig> = Promise<HttpResponse<T, C>>;

export interface HttpInstance<C extends HttpRequestConfig = HttpRequestConfig> extends AxiosInstance {
  (config: C): HttpPromise<unknown, C>;
  (url: string, config?: C): HttpPromise<unknown, C>;
}

export interface HttpRequestConfig<D = unknown> extends InternalAxiosRequestConfig<D> {
  sentry?: { disabled?: boolean; ignoreStatus?: number[] };
}

export interface HttpResponse<T, C extends HttpRequestConfig = HttpRequestConfig> extends AxiosResponse<T> {
  config: C;
}

export interface HttpError<T, C extends HttpRequestConfig = HttpRequestConfig> extends AxiosError<T> {
  config: C;
  response?: HttpResponse<T, C>;
}

export class HttpClient<C extends HttpRequestConfig = HttpRequestConfig> {
  protected client: HttpInstance<C>;

  constructor(config?: C) {
    this.client = axios.create(config) as HttpInstance<C>;

    this.client.interceptors.request.use(undefined, this.report);
    this.client.interceptors.response.use(undefined, this.report);
  }

  public setAuthorization(token: string): void {
    // Set the 'Authorization' header with the provided token
    this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  public get<T, P = object>(url: string, params?: P, config?: C) {
    return this.client.get<T>(url, { params, ...config }).then(this.unwrap);
  }

  public post<T, D = object>(url: string, data?: D, config?: C) {
    return this.client.post<T>(url, data, config).then(this.unwrap);
  }

  public put<T, D = object>(url: string, data?: D, config?: C) {
    return this.client.put<T>(url, data, config).then(this.unwrap);
  }

  public patch<T, D = object>(url: string, data?: D, config?: C) {
    return this.client.patch<T>(url, data, config).then(this.unwrap);
  }

  public delete<T>(url: string, config?: C) {
    return this.client.delete<T>(url, config).then(this.unwrap);
  }

  private shouldReport(sentry: C['sentry'], response?: HttpResponse<unknown>) {
    if (!sentry) return true;

    if (sentry.disabled || !response) return false;

    if (!sentry.ignoreStatus) return true;

    return !sentry.ignoreStatus.includes(response.status);
  }

  protected getReportOptions(error: HttpError<unknown>) {
    return error.isAxiosError
      ? {
          config: { headers: error.config.headers, data: error.config.data, params: error.config.params },
          response: error.response ? error.response.data : {}
        }
      : {};
  }

  protected unwrap = <T>(response: HttpResponse<T>) => {
    return response.data;
  };

  private report = (error: HttpError<unknown>) => {
    const shouldReport =
      this.shouldReport((this.client.defaults as unknown as C).sentry, error.response) &&
      this.shouldReport(error.config.sentry, error.response);

    if (!shouldReport) throw error;

    // Sentry.withScope((scope) => {
    //   scope.setExtras(this.getReportOptions(error));
    //   Sentry.captureException(error, scope);
    // });

    throw error;
  };
}
