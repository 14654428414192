import { Listbox, Props as SelectProps } from '@/components/Select/Listbox';

import { CategoryWidgets } from '@/domain';

export type EstateTypeListboxItem = Pick<CategoryWidgets, 'id' | 'name'>;

export interface Props extends SelectProps<EstateTypeListboxItem> {}

export const EstateTypesListbox = ({ options, ...rest }: Props) => {
  return <Listbox options={options.map(({ id, name }) => ({ id, name }))} {...rest} />;
};
