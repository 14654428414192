import { Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface Props {
  message: string;
  show?: boolean;
  className?: string;
}

export const NotificationItem: React.FC<Props> = ({ message, show, className }) => {
  return (
    <Transition
      enter="transition-transform duration-100"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition-transform duration-100"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      appear
      className={classNames('bg-white px-3 py-4 rounded-2xl shadow-category-card font-medium text-main', className)}
      {...{ show }}
    >
      {message}
    </Transition>
  );
};
