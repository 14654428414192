import { Footer } from './Footer';
import { Header } from './Header';
import { ContactSection } from './sections/ContactSection';
import { EnrollmentSection } from './sections/EnrollmentSection';
import { HeroSection } from './sections/HeroSection';
import { ServicesSection } from './sections/ServicesSection';
import { VideoSection } from './sections/VideoSection';
import { PainpointsSection } from './sections/PainpointsSection';


export const LandingPage = () => {
  return (
    <>
      <Header />

      <main>
        <HeroSection />

        <VideoSection />

        <PainpointsSection />

        <ServicesSection />

        <EnrollmentSection />

        <ContactSection />
      </main>

      <Footer />
    </>
  );
};
