import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

export interface Props {
  item: { question: string; answer: string };
}

export const FaqItem = ({ item }: Props) => (
  <Disclosure as="div" key={item.question} className="pt-6">
    {({ open }) => (
      <>
        <dt>
          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-base leading-7">{item.question}</span>

            <span className="ml-6 flex h-7 items-center">
              {open ? (
                <ChevronUpIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </dt>

        <Disclosure.Panel as="dd" className="mt-2 pr-12">
          <p className="text-base leading-7 text-gray-600">{item.answer}</p>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);
