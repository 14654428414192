import {
  ArrowTrendingDownIcon,
  BuildingLibraryIcon,
  CalculatorIcon,
  CurrencyEuroIcon,
  DocumentTextIcon,
  ClipboardDocumentCheckIcon,
  PresentationChartBarIcon
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react';

import { PaddedComponent } from '@/components/PaddedComponent';

const painpoints = [
  {
    icon: CurrencyEuroIcon,
    title: 'Salvează timp',
    subtitle:
      'În doar un minut puteți afla atât valoarea din studiul de piață al oricărui imobil din județele Cluj, Maramureș, Bistrița-Năsăud sau Sălaj, cât și cuantumul taxelor notariale aferente mai multor proceduri.'
  },
  {
    icon: ArrowTrendingDownIcon,
    title: 'Siguranța rezultatelor',
    subtitle: 'Notario folosește mereu studii de piață, date, liste oficiale și formule de calcul actualizate. Rezultatul fiecărui calcul este explicat în aplicație, pentru ca dumneavoastră să aveți siguranța că acesta este corect și actual.'
  },
  {
    icon: CalculatorIcon,
    title: 'Delegare',
    subtitle: 'Cu Notario, procesul de calcul este atât de simplu și sigur, încât puteți avea încredere deplină în personalul dumneavoastră să se ocupe de el.'
  },
  {
    icon: PresentationChartBarIcon,
    title: 'Economisește bani',
    subtitle:
      'Ce se întamplă când erorile de calcul sunt găsite mai târziu? Se pierd bani sau clienți. Cu Notario, erorile sunt eliminate.'
  },
  {
    icon: DocumentTextIcon,
    title: 'Fidelizează clienții',
    subtitle:
      'Clienții tind să fie mai mulțumiți când primesc răspunsuri mai rapide și exacte, fără erori.'
  },
  {
    icon: ClipboardDocumentCheckIcon,
    title: 'Funcționalități viitoare',
    subtitle:
      'În viitor, în aplicația Notario vor fi disponibile și alte funcționalități precum: verificarea dacă un imobil este monument istoric sau se află într-o zonă protejată istoric, trimiterea sumarului privind taxele notariale către clienți direct din aplicație, consultarea secțiunii de istoric, conversiea monetară la cursul BNR.'
  }
];

export interface Props {}

export const PainpointsSection: React.FC<Props> = () => {
  return (
    <PaddedComponent
      as="section"
      id="painpoints"
      className="relative border-b border-solid border-inherit mb-36 py-10 md:py-20"
    >
      <h1 className="text-2xl text-center mb-5 md:text-4xl">Beneficii</h1>

      <div className="grid grid-cols-1 gap-12 justify-center pt-16 md:grid-cols-2 md:gap-24 md:pt-28">
        {painpoints.map((painpoint) => (
          <PainpointCard key={painpoint.title} {...painpoint} className="mx-auto my-0" />
        ))}
      </div>

      <div className="text-xs md:text-base absolute inset-x-0 bottom-0 translate-y-1/2 flex justify-center">
        <span className="px-5 md:px-12 text-center text-gray-400 bg-white ">Și multe altele</span>
      </div>
    </PaddedComponent>
  );
};

interface PainpointCardProps {
  icon: React.ElementType;
  title: string;
  subtitle: string;
  className?: string;
}

const PainpointCard: React.FC<PainpointCardProps> = ({ icon: Icon, title, subtitle, className }) => {
  return (
      <div
        className={classNames(
          'relative flex flex-col items-left p-5 rounded-2xl shadow-category-card transition-transform duration-300 hover:-translate-y-3 hover:bg-[#527A62] hover:text-white group',
          className
        )}
      >
        <div className="flex items-center">
          <div className="bg-white p-4 rounded-full mr-4">
            <Icon className="w-9 h-9 text-main-light group-hover:text-[#527A62]" />
          </div>
          <div className="font-medium text-sm md:text-base">{title}</div>
        </div>
        
        <div className="mt-2 text-xs text-left md:text-base text-gray-500 group-hover:text-white">{subtitle}</div>
      </div>
    );
};
