import { ArrowPathIcon, EyeIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn, useLocalStorage, useStartTyping } from 'react-use';
import * as yup from 'yup';
import { evaluate } from '@/services/evaluation-engine';

import { Checkbox } from '@/components/Checkbox';
import { Form } from '@/components/Form';
import { SubmitOnValuesChange } from '@/components/Form/SubmitOnValuesChange';
import { Input } from '@/components/Input';
import { Page } from '@/components/Page';
import { Price } from '@/components/Price';
import { RadioGroup } from '@/components/RadioGroup';
import { Listbox } from '@/components/Select/Listbox';
import { TransactionTypesListbox } from '@/components/Selects/TransactionTypesListbox';
import { FadeInAndOutTransition } from '@/components/Transitions/FadeInAndOutTransition';
import { ComputeFeesValueRequestBody, GetFeeWidgetsListResponse, transactionsAccess } from '@/access';
import { TransactionTypeListItem, WidgetType, Widgets } from '@/domain';
import { Currency } from '@/domain/currency';
import { useLoadFeeWidgets } from '@/hooks/useLoadFeeWidgets';
import { useOpen } from '@/hooks/useOpen';
import { ButtonWithIcon } from './Button/ButtonWithIcon';
import { FeeJustificationDialog } from './Dialogs/FeeJustificationDialog';
import ReactGA from "react-ga4";

export interface Props {
  showFillEvaluationValue?: boolean;
  autocompleteEvaluationValue?: boolean;
}

const widgetMapper = (
  name: string,
  index: number,
  widget: Widgets,
  showFillEvaluationValue: boolean,
  onAutocompleteEvaluationValueClick: () => void
) => {
  const common = {
    id: `${widget.id}-${index}-fee`,
    name: `${name}.w${widget.id}`
  };

  const map = {
    integer: 'number',
    text: 'text'
  };

  switch (widget.type) {
    case WidgetType.Select:
      return (
        <Form.Field
          key={widget.name}
          as={Listbox}
          placeholder={widget.placeholder}
          options={widget.options}
          {...common}
        />
      );
    case WidgetType.Input: {
      if (widget.key.includes('agreed-value') && showFillEvaluationValue)
        return (
          <div className="flex">
            <Form.Field
              key={widget.name}
              as={Input}
              type={map[widget.data_type] ?? 'text'}
              placeholder={widget.placeholder}
              {...common}
              className="flex-1 mr-4"
            />

            {/*{widget.key.includes('agreed-value') && showFillEvaluationValue && (*/}
            {/*  <ButtonWithIcon icon={ArrowPathIcon} variant="white" onClick={onAutocompleteEvaluationValueClick}>*/}
            {/*    Preia valoarea*/}
            {/*  </ButtonWithIcon>*/}
            {/*)}*/}
          </div>
        );

      return (
        <Form.Field
          key={widget.name}
          as={Input}
          type={map[widget.data_type] ?? 'text'}
          placeholder={widget.placeholder}
          {...common}
        />
      );
    }
    case WidgetType.Checkbox:
      return <Form.Field key={widget.name} as={Checkbox} {...common} />;
    case WidgetType.Radio:
      return <Form.Field key={widget.name} as={RadioGroup} {...common} options={widget.options} />;
  }
};

const dynamicallyBuildValidationSchema = (feeWidgets: GetFeeWidgetsListResponse) => {
  const props = feeWidgets.reduce<Record<string, any>>((obj, { id, widgets }) => {
    const key = `f${id}`;

    const value = widgets?.reduce<Record<string, any>>((obj, current) => {
      const key = `w${current.id}`;

      if (current.extra_data?.display_if) {
        obj[key] = yup.number();
      } else {
        obj[key] = yup.number().required();
      }

      return obj;
    }, {});

    obj[key] = yup.object(value).required();

    return obj;
  }, {});

  return yup.object(props).required();
};

const minimum_tax_value = 0;

export const Fee: React.FC<Props> = ({ showFillEvaluationValue = false, autocompleteEvaluationValue = false }) => {
  const { state } = useLocation();
  const [transactionType, setTransactionType] = React.useState<TransactionTypeListItem | undefined>(
    state?.transactionType
  );
  const [latestPropertyEvaluation] = useLocalStorage<{ total_price: number }>('estate-evaluation');
  const navigate = useNavigate();

  const feeWidgets = useLoadFeeWidgets({ transaction_type_id: transactionType?.id });

  const schema = React.useMemo(() => dynamicallyBuildValidationSchema(feeWidgets.data ?? []), [feeWidgets.data, transactionType?.id]);

  const [feesValue, computeFeesValue] = useAsyncFn(
    async (fee_widget_answers?: ComputeFeesValueRequestBody['fee_widget_answers']) => {
      if (!transactionType?.id || !fee_widget_answers?.length) return;

      ReactGA.event({
        category: 'User Interaction',
        action: 'Compute Fee Value',
        label: 'Compute Fee Value',
      });
      return transactionsAccess.computeFeesValue({
        transaction_type_id: transactionType?.id,
        minimum_tax_value,
        fee_widget_answers
      });
    },
    [transactionType?.id]
  );

 const [requiredWidgets, setWidgetsWithDisplayIf] = useState();
  
  React.useEffect(() => {
    computeFeesValue();
  }, [transactionType?.id]);

  const initialValues = React.useMemo(() => {
    if (!autocompleteEvaluationValue || !latestPropertyEvaluation?.total_price) return;

    const agreedValueWidget = (
      feeWidgets.data?.flatMap(
        ({ id: fId, widgets }) => widgets?.map(({ id: wId, key }) => ({ fId, wId, key })) ?? []
      ) ?? []
    ).find(({ key }) => key.includes('agreed-value'));

    if (!agreedValueWidget) return;

    const { fId, wId } = agreedValueWidget;

    if (!state?.initialValues) {
      return {
        [`f${fId}`]: {
          [`w${wId}`]: latestPropertyEvaluation.total_price
        }
      };
    }

    const { [`f${fId}`]: feeId, ...rest } = state.initialValues;

    return {
      [`f${fId}`]: { ...feeId, [`w${wId}`]: latestPropertyEvaluation.total_price },
      ...rest
    };
  }, [autocompleteEvaluationValue, feeWidgets.data, state?.initialValues]);

  const justificationDialog = useOpen();

  return (
    <Page className="lg:flex">
      <div className="lg:w-1/2 lg:pr-7 xl:pr-32">
        <h2 className="font-medium mb-1 md:text-lg">Selectare procedură</h2>

        <p className="text-xs mb-5 text-gray-500">Selectați tipul procedurii pentru a continua</p>

        <TransactionTypesListbox
          value={transactionType}
          onChange={(newValue) => {
            setTransactionType(newValue); 
            ReactGA.event({
              category: 'User Interaction',
              action: 'Transaction Type Change',
              label: `Selected Transaction Type: ${newValue?.id}`,
            });
          }}
          placeholder="Selectează procedura"
        />

        <FadeInAndOutTransition show={!feeWidgets.isLoading && !!feeWidgets.data}>
          <Form
            id="transaction-form"
            {...{ initialValues }}
            key={transactionType?.id}
              // @ts-ignore
            onSubmit={(values, { setValues }) => {

              // TODO: Refactor all this
              const widgetsWithDisplayIf = feeWidgets.data?.map(({widgets }) => {
               return widgets?.filter((widget) => {
                const condition = !!(widget.extra_data?.display_if
                  ? evaluate(values)(widget.extra_data.display_if)
                  : false);
                  return condition;
               })
              }) 

              // @ts-ignore
              const requiredWidgetsNotEmpty = widgetsWithDisplayIf.some((innerArray) => innerArray.length > 0);

              if(requiredWidgetsNotEmpty) {
              // @ts-ignore
              setWidgetsWithDisplayIf(widgetsWithDisplayIf);
              
            }
            
              const cazSimply = feeWidgets.data?.map(({widgets }) => {
                return widgets?.filter((widget) => {
                 const condition = !!(widget.extra_data?.display_if
                   ? evaluate(values)(widget.extra_data.display_if)
                   : true);
                   return condition;
                })
               }) 

              // @ts-ignore
              const hasValuecazSimply = cazSimply.some((innerArray) => innerArray.length > 0);

              if(hasValuecazSimply && requiredWidgets !== undefined) {
              // @ts-ignore
              const extractedData = requiredWidgets.map((items) => {
                  // @ts-ignore
                return items.map((item) => {
                  const { extra_data, options } = item;
                  const rhs = extra_data?.display_if?.rhs;
                  // @ts-ignore
                  const ids = options.map((option) => option.id).flat();
              
                  return { rhs, ids };
                });
              });
              // @ts-ignore
              //TO DO make filteredData more generic now i am considering only the first nested arrays
              const filteredData = extractedData.filter((items) => items.length > 0);

              for (const outerKey in values) {
                if (Object.hasOwnProperty.call(values, outerKey)) {
              
                    const innerObject = values[outerKey];

                  // Check if the inner object contains any key with a value from ids array
                  const containsIds = Object.values(innerObject).some(value => filteredData[0][0].ids.includes(value));

                  // Check if the inner object contains a key with the value of rhs
                  const containsRhs = Object.values(innerObject).includes(filteredData[0][0].rhs);
              
                  // If it contains ids but not rhs, remove the key-value pair with a value from ids array
                  if (containsIds && !containsRhs) {
                    for (const key in innerObject) {
                      if (Object.hasOwnProperty.call(innerObject, key) && filteredData[0][0].ids.includes(innerObject[key])) {
                        delete innerObject[key];
                      }
                    }
                  }
                }
              }
            }

              const props = Object.entries(values).map(([key, value]) => {
                const fee_id = parseInt(key.substring(1));
                const widgets = Object.entries(value).map(([key, value]) => {
                  const widget_id = parseInt(key.substring(1));

                  return {
                    widget_id,
                    value
                  };
                });
                return { fee_id, widgets };
              });
              // @ts-ignore
              computeFeesValue(props);
              setValues({});
            }}
            schema={schema}
            className="mt-7 space-y-8"
          >
            
            {({ values }) => {
              
              return (
              <>
                <SubmitOnValuesChange />
              
                {feeWidgets.data?.map(({ id, name, widgets }) => (
                  <div key={`fee-${id}`}>
                    <div className="font-medium mb-1">{name}</div>

                    <div className="space-y-5">
                    
                      {widgets
                        ?.filter((widget) => {
                          const condition = !!(widget.extra_data?.display_if
                            ? evaluate(values)(widget.extra_data.display_if)
                            : true);
                          return condition;
                        })
                        .map((widget, index) => {
                          return (
                          <div key={index}>
                            <div className="text-xs text-gray-500 mb-3">{widget.label}</div>
                            {widgetMapper(`f${id.toString()}`, index, widget, showFillEvaluationValue, () => {
                              navigate('/home/evaluare-imobile/taxe-notariale', {
                                state: {
                                  transactionType,
                                  initialValues: values
                                }
                              });
                            })}
                          </div>
                        )})}
                    </div>
                  </div>
                ))}
              </>
            )}}
          </Form>
        </FadeInAndOutTransition>
      </div>

      <div className="mt-14 lg:w-1/2 lg:mt-0">
        <FadeInAndOutTransition show={!!transactionType && !!feesValue.value}>
          <h2 className="font-medium mb-1 md:text-lg">Taxe notariale</h2>

          <div className="grid">
            <div className="grid grid-cols-1 md:grid-cols-2 odd:bg-white even:bg-gray-50 p-3">
              <p className="text-sm text-gray-500 mb-5">Valoare imobil</p>

              <div className="flex flex-col justify-between">
                <ul className="flex flex-col space-y-1">
                  {[
                    { name: 'Valoare declarată', value: feesValue.value?.agreed_value_tax.agreed_value },
                    { name: 'Valoare minimă', value: latestPropertyEvaluation?.total_price }
                  ].map((fee, index) => (
                    <li key={index}>
                      <span>{`${fee.name}: `}</span>

                      {fee.value ? <Price amount={fee.value} currency={Currency.RON} /> : <span>n/a</span>}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {feesValue.value?.parts.map(({ name, taxes, total }, index) => {
              return (
                <div key={index} className="grid grid-cols-1 md:grid-cols-2 odd:bg-white even:bg-gray-50 p-3">
                  <p className="text-sm text-gray-500 mb-5">Taxe {name.toLowerCase()}</p>

                  <div className="flex flex-col justify-between">
                    <ul className="flex flex-col space-y-1">
                      {taxes.map((fee, index) => (
                        <li key={index}>
                          <span>{`${fee.name}: `}</span>

                          <Price amount={fee.value} currency={Currency.RON} />
                          {fee.name === 'Taxa onorariu' ? ' (TVA inclus)' : ''}
                        </li>
                      ))}
                    </ul>

                    <div className="font-bold mt-6">
                      <span className="uppercase">Total:&nbsp;</span>

                      <Price amount={total} currency={Currency.RON} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="my-4">
            <ButtonWithIcon icon={EyeIcon} className="w-full lg:w-auto" slim onClick={justificationDialog.open}>
              Vezi justificarea
            </ButtonWithIcon>
          </div>

          {!!feesValue.value && <FeeJustificationDialog {...justificationDialog} value={feesValue.value} />}
        </FadeInAndOutTransition>
      </div>
    </Page>
  );
};
