import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const DialogCloseButton: React.FC<Props> = ({ className, ...props }) => {
  return (
    <button className={classNames('text-gray-400 hover:text-main', className)} {...props}>
      <XMarkIcon className="w-6 h-6" />
    </button>
  );
};
