import { HttpRequestConfig, api } from '@/util/networking';
import axios, { AxiosInstance } from 'axios';
import { AxiosRequestHeaders } from 'axios';
import { API } from '@/config';

export interface ProfileResponse {
    id: number
    email: string,
    first_name: string, 
    last_name: string,
    phone_number: string,
    office_name: string,
}

const profileData = () => {
  return api.get<ProfileResponse>('/v1/users/me/test/');
};

export const setBearerToken = (token: string) => {
  api.setAuthorization(`${token}`);
};

export const profileAccess = {
  profileData
};
