import classNames from 'classnames';
import React from 'react';

import { Button, Props as ButtonProps, Variant as ButtonVariant } from './Button';

export interface Props extends ButtonProps {
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
  iconPosition?: 'before-text' | 'after-text';
}

const style: Record<ButtonVariant, string> = {
  white: 'text-gray-700',
  green: 'text-white',
  lightGreen: 'text-[#527A62]'
};

export const ButtonWithIcon = ({
  children,
  icon: Component,
  iconPosition = 'before-text',
  variant = 'green',
  className,
  ...rest
}: React.PropsWithChildren<Props>) => {
  return (
    <Button className={classNames(style[variant], className)} {...{ ...rest, variant }}>
      {iconPosition === 'before-text' && (
        <Component
          className={classNames('h-5 w-5 min-w-[1.25rem]', children && 'mr-2', style[variant])}
          aria-hidden="true"
        />
      )}

      {children}

      {iconPosition === 'after-text' && (
        <Component
          className={classNames('h-5 w-5 min-w-[1.25rem]', children && 'ml-2', style[variant])}
          aria-hidden="true"
        />
      )}
    </Button>
  );
};
