import classNames from 'classnames';
import { useCopyToClipboard } from 'react-use';

import { currencyConverter } from '@/services/currency-converter';

import { Notification } from '@/components/Notification';

import { Currency } from '@/domain/currency';

export interface Props {
  amount: number;
  currency: Currency;
  className?: string;
}

const currencyFormat: Record<Currency, string> = {
  [Currency.RON]: 'lei',
  [Currency.EUR]: 'EURO'
};

export const Price = ({ amount, currency, className }: Props) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const notifications = Notification.Container.useContainer();

  const [integer, decimal] = amount.toFixed(2).split('.');

  // TODO: Use a RegEx for this
  const formattedInteger = integer
    .split('')
    .reverse()
    .join('')
    .replace(/(.{3})/g, '$1.');
  const rebuiltAmount = `${formattedInteger.split('').reverse().join('')},${decimal}`;
  const formattedAmount = rebuiltAmount[0] === '.' ? rebuiltAmount.slice(1) : rebuiltAmount;

  return (
    <span
      className={classNames('hover:text-main hover:cursor-copy', className)}
      onClick={() => {
        copyToClipboard(`${amount} (${currencyConverter.toText(amount)})`);
        notifications.push('Valoare copiată (cu cifre și litere)');
      }}
    >
      {formattedAmount} {currencyFormat[currency]}
    </span>
  );
};
