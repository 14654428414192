import { Transition } from '@headlessui/react';

export interface Props {
  show: boolean;
  as?: React.ElementType;
  className?: string;
}

export const FadeInAndOutTransition = (props: React.PropsWithChildren<Props>) => {
  return (
    <Transition
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      {...props}
    />
  );
};
