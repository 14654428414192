import { api } from '@/util/networking';

import { AreaListItem, CountyListItem, SettlementListItem, StreetListItem } from '@/domain';

export type GetCountiesListResponse = CountyListItem[];

const getCountiesList = () => {
  return api.get<GetCountiesListResponse, GetSettlementListQuery>('/v1/locations/counties/');
};

export interface GetSettlementListQuery {
  county_id: number;
  name?: string;
}

export type GetSettlementsListResponse = {
  cities: SettlementListItem[];
  villages: SettlementListItem[];
};

const getSettlementsList = (options: GetSettlementListQuery) => {
  return api
    .get<GetSettlementsListResponse, GetSettlementListQuery>('/v1/locations/settlements/', options)
    .then(({ cities, villages }) =>
      cities
        .map(({ id, ...rest }) => ({ id: `city-${id}`, ...rest, type: `city` }))
        .concat(villages.map(({ id, ...rest }) => ({ id: `village-${id}`, ...rest, type: 'village' })))
    );
};

export interface GetStreetsListQuery {
  city_id: string;
  name?: string;
}

export type GetStreetsListResponse = StreetListItem[];

const getStreetsList = (options: GetStreetsListQuery) => {
  return api.get<GetStreetsListResponse, Omit<GetStreetsListQuery, 'city_id'> & { city_id: number }>(
    '/v1/locations/streets/',
    {
      ...options,
      city_id: parseInt(options.city_id.split('-')[1])
    }
  );
};

export interface GetAreasListQuery {
  county_id?: number;
  street_id?: number;
  city_id?: string;
  village_id?: string;
  name?: string;
}

export type GetAreasListResponse = AreaListItem[];

const getAreasList = (options: GetAreasListQuery) => {
  return api.get<GetAreasListResponse, GetAreasListQuery>('/v1/locations/areas/', options);
};

export const placesAccess = {
  getCountiesList,
  getSettlementsList,
  getStreetsList,
  getAreasList
};
