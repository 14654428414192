import {
  HandThumbDownIcon as HandThumbDownIconOutline,
  HandThumbUpIcon as HandThumbUpIconOutline
} from '@heroicons/react/24/outline';
import { ArrowRightIcon, EyeIcon } from '@heroicons/react/24/solid';
import arrayMutators from 'final-form-arrays';
import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn, useLocalStorage } from 'react-use';
import * as yup from 'yup';

import { isObject } from '@/util/type-guards';

import { AddEstateButton } from '@/components/AddEstateButton';
import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';
import { Card } from '@/components/Card';
import { EvaluationFeedbackDialog } from '@/components/Dialogs/EvaluationFeedbackDialog';
import { EvaluationJustificationDialog } from '@/components/Dialogs/EvaluationJustificationDialog';
import { EstateEvaluationDataField } from '@/components/EstateEvaluationDataField';
import { FeedbackButton } from '@/components/FeedbackButton';
import { Form } from '@/components/Form';
import { SaveToLocalStorage } from '@/components/Form/SaveToLocalStorage';
import { SubmitOnDependencyChange } from '@/components/Form/SubmitOnDependencyChange';
import { SubmitOnValuesChange } from '@/components/Form/SubmitOnValuesChange';
import { Input } from '@/components/Input';
import { Notification } from '@/components/Notification';
import { Page } from '@/components/Page';
import { Price } from '@/components/Price';
import { AreasLoadableCombobox } from '@/components/Selects/AreasLoadableCombobox';
import { CountiesLoadableListbox } from '@/components/Selects/CountiesLoadableListbox';
import { SettlementsLoadableCombobox } from '@/components/Selects/SettlementsLoadableCombobox';
import { StreetsLoadableCombobox } from '@/components/Selects/StreetsLoadableCombobox';
import { FadeInAndOutTransition } from '@/components/Transitions/FadeInAndOutTransition';

import { feedbackAccess } from '@/access';
import { ComputeEstateValueRequestBody, GetCategoryWidgetsListResponse, taxesAccess } from '@/access';
import {
  AreaListItem,
  CountyListItem,
  SettlementListItem,
  SettlementSubdivision,
  StreetListItem,
  WidgetType,
  Widgets
} from '@/domain';
import { Currency } from '@/domain/currency';
import { useLoadCategoryWidgets } from '@/hooks/useLoadCategoryWidgets';
import { useOpen } from '@/hooks/useOpen';
import { useTimeoutTrigger } from '@/hooks/useTimeoutTrigger';

import { useDetermineArea } from './hooks/useDebounceDetermineArea';
import ReactGA from "react-ga4";

export interface Props {}

interface Options {
  county?: CountyListItem;
  settlement?: SettlementListItem;
  area?: AreaListItem;
  street?: StreetListItem;
  streetNumber?: string;
}

const getAddress = ({ settlement, county, street, area, streetNumber }: Options) => {
  return `${settlement?.name} / ${county?.name} ${
    street && streetNumber ? `- ${street.name} Nr. ${streetNumber}` : ''
  }, zona ${area?.name}`;
};

const getSettlementId = (settlement: { id: string }) => {
  const [type, id] = settlement.id.split('-');

  return type === 'city' ? { city: parseInt(id) } : { village: parseInt(id) };
};

const widgetValidationMapper = ({ estateTypeId, ...widget }: Widgets & { estateTypeId: number }) => {
  switch (widget.type) {
    case WidgetType.Select:
      return yup.mixed().when('estateType.id', { is: estateTypeId, then: (schema) => schema.required() });
    case WidgetType.Input: {
      const map = {
        integer: yup
          .number()
          .positive()
          .when('estateType.id', { is: estateTypeId, then: (schema) => schema.required() }),
        text: yup.string().when('estateType.id', { is: estateTypeId, then: (schema) => schema.required() })
      };
      return map[widget.data_type];
    }
    case WidgetType.Checkbox:
      return yup.boolean().when('estateType.id', { is: estateTypeId, then: (schema) => schema });
  }
};

const dynamicallyBuildValidationSchema = (categoryWidgets: GetCategoryWidgetsListResponse) => {
  const widgets = categoryWidgets.flatMap(({ id, widgets }) => widgets.map((w) => ({ estateTypeId: id, ...w }))) ?? [];
  const props = widgets.reduce<Record<string, ReturnType<typeof widgetValidationMapper>>>((obj, widget) => {
    obj[`p${widget.id}`] = widgetValidationMapper(widget);
    return obj;
  }, {});

  return yup.object({
    properties: yup
      .array()
      .of(
        yup
          .object({
            estateType: yup.object({ id: yup.number().required() }).required(),
            share: yup
              .object({
                part: yup.number().positive(),
                total: yup.number().min(yup.ref('part'))
              })
              .optional(),
            ...props
          })
          .required()
      )
      .required()
  });
};

export const EvaluationPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const notifications = Notification.Container.useContainer();

  const [s, setState] = useLocalStorage<{
    county?: CountyListItem;
    settlement?: SettlementListItem | null;
    street?: StreetListItem | null;
    streetNumber?: string;
    settlementSearchQuery: string;
    streetSearchQuery: string;
    area?: AreaListItem;
    category_widget_answers?: ComputeEstateValueRequestBody['category_widget_answers'];
  }>('estate-location', {
    settlementSearchQuery: '',
    streetSearchQuery: '',
    category_widget_answers: []
  });

  const [estateConfig, setEstateConfig] = useState();
  const [_, setEstateEvaluation] = useLocalStorage('estate-evaluation');

  React.useEffect(() => {
    setState({
      settlementSearchQuery: '',
      streetSearchQuery: '',
      category_widget_answers: []
    });
    setEstateConfig(undefined);
    setEstateEvaluation(undefined);
  }, [location.state?.key]);

  // This is always defined since we set the initial value
  const state = s as {
    county?: CountyListItem;
    settlement?: SettlementListItem;
    street?: StreetListItem;
    streetNumber?: string;
    settlementSearchQuery: string;
    streetSearchQuery: string;
    area?: AreaListItem;
    category_widget_answers?: ComputeEstateValueRequestBody['category_widget_answers'];
  };

  const { value: area } = useDetermineArea({
    county: state.county,
    settlement: state.settlement,
    street: state.street,
    streetNumber: state.streetNumber
  });

    const onAreaChange = (area?: AreaListItem) => {
        if (state.area?.id !== area?.id) {
            setState({...state, area, category_widget_answers: []});
            computeEstateValue();
            ReactGA.event({
                category: 'User Interaction',
                action: 'Edit Area',
                label: 'Edit Area',
            });
        }
    };

  React.useEffect(() => {
    if (!area) return;

    onAreaChange(area);
  }, [area]);

  const categoryWidgets = useLoadCategoryWidgets({
    county_id: state.county?.id,
    area_id: state.area?.id
  });

  // TODO: serve from local storage until the api call is made
  const [estateValue, computeEstateValue] = useAsyncFn(
    async (category_widget_answers?: ComputeEstateValueRequestBody['category_widget_answers']) => {
      if (!state.area?.id || !category_widget_answers?.length) return;
      setState({ ...state, category_widget_answers });
      ReactGA.event({
        category: 'User Interaction',
        action: 'Compute estate value',
        label: 'Compute estate value',
      });
      return taxesAccess.computeEstateValue({ area_id: state.area?.id, category_widget_answers });
    },
    [state.area?.id, state.streetNumber]
  );

  React.useEffect(() => {
    setEstateEvaluation(estateValue.value);
  }, [estateValue.value]);

  const feedbackDialog = useOpen();
  const justificationDialog = useOpen();

  const schema = React.useMemo(
    () => dynamicallyBuildValidationSchema(categoryWidgets.data ?? []),
    [categoryWidgets.data]
  );

  const [messageIsShown, showMessage] = useTimeoutTrigger();

  return (
    <Page className="flex flex-col lg:flex-row">
      <Helmet>
        <title>Valoarea imobilului din studiul de piață</title>
      </Helmet>

      <div className="mb-10 lg:w-1/2 lg:pr-4 lg:mb-0">
        <h1 className="text-center font-bold text-lg color: text-red-700 mb-10"> Momentan aplicația Notario folosește valorile din Studiile de Piață aferente anului 2023. În curând, platforma va fi actualizată conform Studiilor de Piață 2024. </h1>
        <div className="mb-10">
          <h1 className="font-bold mb-1 text-lg">Selectare Locație</h1>

          <p className="mb-10">Selectați intâi locația pentru a continua către următorul pas</p>

          <Card className="space-y-6">
            <div className="flex flex-col space-y-6 xl:flex-row xl:space-y-0">
              <CountiesLoadableListbox
                id="county"
                onChange={(county) => {
                  setState({ county, settlementSearchQuery: '', streetSearchQuery: '',
                    category_widget_answers: [], settlement: null
                  });
                }}
                value={state?.county}
                label="Județ"
                placeholder="Alege județul"
                className="xl:w-1/2 xl:pr-3"
              />

              <SettlementsLoadableCombobox
                id="settlement"
                onChange={(settlement) => {
                  setState({
                    county: state?.county,
                    settlementSearchQuery: state?.settlementSearchQuery,
                    settlement,
                    streetSearchQuery: '',
                    street: null,
                    streetNumber: '',
                    category_widget_answers: []
                  });
                  computeEstateValue();
                }}
                value={state?.settlement}
                label="Localitate, comună sau sat"
                placeholder="Introduceți numele"
                disabled={!state.county}
                county_id={state.county?.id}
                className="xl:w-1/2 xl:pl-3"
              />
            </div>

            <FadeInAndOutTransition
              show={!!state.settlement?.subdivisions.includes(SettlementSubdivision.Street)}
              className="flex flex-col space-y-6 xl:flex-row xl:space-y-0"
            >
              <StreetsLoadableCombobox
                id="street"
                onChange={(street) => {
                  setState({
                    county: state?.county,
                    settlementSearchQuery: state?.settlementSearchQuery,
                    settlement: state?.settlement,
                    street,
                    streetSearchQuery: state?.settlementSearchQuery,
                    streetNumber: '',
                  });
                }}
                value={state?.street}
                label="Stradă"
                placeholder="Introduceți numele străzii"
                disabled={!state.settlement}
                city_id={state.settlement?.id}
                className="xl:w-1/2 xl:pr-3"
              />

              <Input
                id="number"
                type="text"
                label="Număr"
                placeholder="Introduceți numărul"
                value={state.streetNumber}
                onChange={(event) => {
                  setState({ ...state, streetNumber: event.target.value ?? '' });
                }}
                disabled={!state.street}
                className="xl:w-1/2 xl:pl-3"
              />
            </FadeInAndOutTransition>

            <FadeInAndOutTransition show={!!state.area}>
              <p className="text-sm text-gray-500">Conform studiului de piață, imobilul e încadrat în această zonă: </p>

              <AreasLoadableCombobox
                id="area"
                onChange={onAreaChange}
                value={state.area}
                county_id={state?.county?.id}
                settlement_id={state?.settlement?.id}
              />
            </FadeInAndOutTransition>
          </Card>
        </div>

        <FadeInAndOutTransition show={!!state.area && !!categoryWidgets.data}>
          <h1 className="font-bold mb-1 text-lg">Categorie de imobile</h1>

          <p className="mb-10">Selectați entitatea dorită pentru a continua</p>

          <Card>
            <Form
              id="properties-form"
              initialValues={estateConfig}
              schema={schema}
              mutators={arrayMutators}
              className="space-y-3"
              onSubmit={({ properties }) => {

                const props = properties.map(({ estateType, share, ...rest }) => ({
                  category_id: estateType.id,
                  ...(share?.part &&
                    share?.total && {
                      surface_quota: {
                        quota_value: share.part,
                        out_of: share.total
                      }
                    }),
                    widgets: Object.entries(rest).map(([key, value]) => ({
                    widget_id: parseInt(key.slice(1)),
                    value: isObject(value) ? value.id : (value as string | number)
                  }))
                }));

                computeEstateValue(props);
              }}
            >
              {({ id, values }) => {
                return (
                  <>

                    <SubmitOnValuesChange />

                    <SubmitOnDependencyChange dependency={state.area?.id} />

                    <FieldArray name="properties">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => {
                            return(
                            <EstateEvaluationDataField
                              key={name}
                              {...{ id, index, name, values, fields, categoryWidgets: categoryWidgets.data ?? [] }}
                              onDeleteEstateButtonClicked={() => {
                                if (fields.length === 1 && index === 0) {
                                  computeEstateValue();
                                }
                              }}
                            />
                          )})}

                          <AddEstateButton
                            onClick={() => {
                              fields.push({});
                              ReactGA.event({
                                category: "User Interaction",
                                action: "Add estate",
                                label: "Add estate",
                              });
                            }}
                          >
                            Adaugă imobil
                          </AddEstateButton>
                        </>
                      )}
                    </FieldArray>
                  </>
                );
              }}
            </Form>
          </Card>
        </FadeInAndOutTransition>
      </div>

      <FadeInAndOutTransition show={!!state.area && !!estateValue.value} className="lg:w-1/2 lg:pl-4">
        <h1 className="font-bold text-lg mb-10">Valoarea de expertiza a imobilului</h1>

        <div className="grid">
          <div className="grid grid-cols-1 md:grid-cols-2 odd:bg-white even:bg-gray-50 p-3">
            <p className="text-sm text-gray-500 mb-5">Adresa</p>

            <div>
              <p className="font-semibold">{getAddress(state)}</p>

              {/* <div role="alert" className="text-red-600 flex items-center">
                <InformationCircleIcon className="w-5 h-5 mr-3" />

                <span>Această adresă se incadrează ca zonă protejată istoric</span>
              </div> */}
            </div>
          </div>

          {estateValue.value?.category_taxes && (
            <div className="grid grid-cols-1 md:grid-cols-2 odd:bg-white even:bg-gray-50 p-3">
              <p className="text-sm text-gray-500 mb-5">Imobil</p>

              <div className="flex justify-between items-center">
                <ul className="flex flex-col">
                  {estateValue.value?.category_taxes.map((estate, index) => (
                    <li key={index} className="font-semibold">
                      {index + 1} - {estate.category_name}: <Price amount={estate.tax_value} currency={Currency.RON} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {estateValue.value?.total_price && (
            <div className="grid grid-cols-1 md:grid-cols-2 odd:bg-white even:bg-gray-50 p-3">
              <p className="text-sm text-gray-500 mb-5 uppercase">Total</p>

              <div className="text-main font-semibold">
                <Price amount={estateValue.value.total_price} currency={Currency.RON} />
              </div>
            </div>
          )}
        </div>

        <div className="mt-7 mb-6 xl:flex xl:flex-row justify-between">
          {estateValue.value && <EvaluationJustificationDialog {...justificationDialog} value={estateValue.value} />}

          <EvaluationFeedbackDialog
            {...feedbackDialog}
            onSubmit={(values) => {
              if (!state.county || !state.area) return;

              return feedbackAccess
                .submitFeedback({
                  is_correct: false,
                  category_widgets_payload: state.category_widget_answers,
                  tax_computation_result_payload: estateValue.value,
                  county: state.county.id,
                  computed_area: state.area?.id,
                  street: state.street?.id,
                  ...(state.settlement ? getSettlementId(state.settlement) : {}),
                  ...values
                })
                .then(() => {
                  notifications.push('Mulțumim pentru recenzie!');
                });
            }}
          />

          <div className="flex flex-col w-full md:flex-col-reverse">
            <div className="space-x-2 flex items-center pb-6 lg:pb-0">
              <span className="font-medium text-main">Cum a fost experiența dvs. cu acest calcul?</span>

              <FeedbackButton
                icon={HandThumbUpIconOutline}
                onClick={() => {
                  if (!state.county || !state.area) return;
                  feedbackAccess
                    .submitFeedback({
                      is_correct: true,
                      category_widgets_payload: state.category_widget_answers,
                      tax_computation_result_payload: estateValue.value,
                      county: state.county.id,
                      computed_area: state.area?.id,
                      street: state.street?.id,
                      ...(state.settlement ? getSettlementId(state.settlement) : {})
                    })
                    .then(() => {
                      showMessage();
                    });
                }}
              />

              <FeedbackButton variant="red" icon={HandThumbDownIconOutline} onClick={feedbackDialog.open} />
            </div>

            <div className="flex flex-col space-y-4 lg:pb-6 lg:space-y-0 lg:flex-row lg:justify-between">
              <ButtonWithIcon icon={EyeIcon} className="w-full lg:w-auto" slim onClick={justificationDialog.open}>
                Vezi justificarea
              </ButtonWithIcon>

              <ButtonWithIcon
                icon={ArrowRightIcon}
                className="w-full lg:w-auto"
                onClick={() => {
                  navigate('taxe-notariale');
                  ReactGA.event({
                    category: "User Interaction",
                    action: "Go to phase 2",
                    label: "Go to phase 2",
                  });
                }}
                slim
              >
                Calculează taxele notariale
              </ButtonWithIcon>
            </div>
          </div>
        </div>

        <FadeInAndOutTransition show={messageIsShown} className="font-medium text-main">
          Mulțumim pentru recenzie!
        </FadeInAndOutTransition>
      </FadeInAndOutTransition>
    </Page>
  );
};
