import { Transition } from '@headlessui/react';
import { Bars2Icon, XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Logo } from '@/components/Logo';
import { PaddedComponent } from '@/components/PaddedComponent';

import { links } from '@/constants/constants';
import { useOpen } from '@/hooks/useOpen';

export interface Props {}

export const Header: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { isOpen, open, close } = useOpen();

  return (
    <PaddedComponent as="header" className="relative z-30 flex text-white items-center justify-between py-4">
      <Logo logoClassName="h-9 w-9" color="green" showText textClassName="text-sm font-semibold" />

      <Bars2Icon className="md:hidden w-6 h-6 text-main hover:text-main-dark hover:cursor-pointer" onClick={open} />

      <div className="hidden md:flex items-center text-black font-medium space-x-6 lg:space-x-10">
        {links.map(({ href, name }) => (
          <a key={href} {...{ href }}>
            {name}
          </a>
        ))}

        {/* <ButtonWithIcon
          icon={ArrowRightIcon}
          type="button"
          onClick={() => {
            navigate(routes.app.main);
          }}
          slim
        >
          Deschide aplicația
        </ButtonWithIcon> */}
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="flex flex-col absolute inset-0 h-[100vh] bg-main p-5 md:hidden"
      >
        <div className="flex justify-between items-center">
          <Logo logoClassName="w-9 h-9" textClassName="text-sm font-semibold" showText />

          <XMarkIcon className="w-6 h-6 text-white hover:cursor-pointer" onClick={close} />
        </div>

        <div className="flex flex-1 flex-col justify-center space-y-20">
          {links.map(({ href, name }) => (
            <button
              key={href}
              onClick={() => {
                close();
                navigate(href);
              }}
            >
              {name}
            </button>
          ))}

          {/* <div className="flex justify-center">
            <ButtonWithIcon
              icon={ArrowRightIcon}
              type="button"
              variant="white"
              onClick={() => {
                navigate(routes.app.main);
              }}
              slim
            >
              Deschide aplicația
            </ButtonWithIcon>
          </div> */}
        </div>
      </Transition>
    </PaddedComponent>
  );
};
