import { Listbox, Props as SelectProps } from '@/components/Select/Listbox';

import { TransactionTypeListItem } from '@/domain';
import { useLoadTransactionTypes } from '@/hooks/useLoadTransactionTypes';

export interface Props extends Omit<SelectProps<TransactionTypeListItem>, 'options'> {}

export const TransactionTypesListbox = ({ disabled, ...rest }: Props) => {
  const transactionTypes = useLoadTransactionTypes();

  return <Listbox options={transactionTypes.data ?? []} disabled={transactionTypes.isLoading || disabled} {...rest} />;
};
