import React from 'react';

import { ValidationError, ValidationMessage } from '@/components/ValidationMessage';

export interface Props {
  invalid?: boolean;
  error?: ValidationError | null;
}

export const withValidation = <P extends { invalid?: boolean }, SP>(
  Component: React.ComponentType<P>,
  staticProps?: Partial<SP>
) => {
  const WithValidationComponent: React.FC<P & Props & Partial<SP>> = ({ invalid, error, ...rest }) => {
    const normalizedError = error || ({} as any);

    return (
      <div className="w-full">
        {/* @ts-ignore */}
        <Component invalid={!!error} {...staticProps} {...rest} />

        <ValidationMessage visible={!!invalid} id={normalizedError.id} values={normalizedError.values} />
      </div>
    );
  };

  return WithValidationComponent;
};
