import { ArrowTrendingDownIcon, CalculatorIcon, CurrencyEuroIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaqItem } from '@/components/FaqItem';
import { NavigationCard } from '@/components/NavigationCard';
import { Page } from '@/components/Page';
import { ButtonWithIcon } from '../components/Button/ButtonWithIcon';
import { openExternalSiteInNewTab } from '@/constants/constants';

export interface Props {}

const navigation = [
  {
    to: 'evaluare-imobile',
    icon: ArrowTrendingDownIcon,
    title: 'Valoarea imobilului din studiul de piață',
    subtitle: 'Află valoarea minimă a imobilului conform studiului de piață din iulie 2023'
  },
  {
    to: 'taxe-notariale',
    icon: CalculatorIcon,
    title: 'Taxe notariale',
    // TODO: Change this text
    subtitle: 'Calculează toate taxele notariale aferente procedurilor și obține un sumar al acestora'
  }
  // {
  //   to: routes.rates,
  //   icon: CurrencyEuroIcon,
  //   title: 'Tarife notariale',
  //   subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
  // }
];

const faqs = [
  {
    question: 'Stocăm datele introduse în aplicație?',
    answer:
      'Singura secțiune în care se regăsesc date cu privire la imobilele pentru care ați calculat deja valorile și taxele este secțiunea de Istoric, care vine în ajutorul dumneavoastră pentru a găsi mult mai ușor informații cu privire la acestea, respectiv să reluați o sesiune de calcul din trecut, în cazul în care aceasta nu a fost finalizată. Date despre orice calcul sau interacțiune cu platforma pot fi stocate și revizuite de echipa Notario, cu scopul de a urmări acuratețea datelor și a identifica posibile probleme, pentru a îmbunătăți platforma în general. Ele nu vor fi folosite în niciun alt scop.'
  },
  {
    question: 'Valorile generate de aplicație sunt cele din Studiile de Piață?',
    answer:
      'Da. Aplicația Notario ușurează accesarea studiilor de piață privind valorile minime imobiliare publicate de către Uniunea Națională a Notarilor Publici din România pe site-ul www.unnpr.ro, fără să denatureze datele regăsite în aceste studii, ci folosindu-le întocmai.'
  },
  {
    question: 'Pentru ce zonă teritorială pot folosi aplicația?',
    answer:
      'Momentan, aplicația Notario poate fi folosită pentru interogarea datelor regăsite în Studiile de Piață 2023 aferente județelor Cluj, Sălaj, Bistrița-Năsăud și Maramureș. În viitor, intenționam să dezvoltăm aplicația pentru imobilele de pe întreg teritoriul țării.'
  },
  {
    question:
      'Este nevoie de mai multe date despre imobil pentru a calcula valoarea minimă a acestuia și taxele notariale dacă folosesc aplicația, față de modul clasic de calcul al acestora?',
    answer:
      'Nu. Pentru a calcula prin intermediul aplicației date despre imobil și taxele aferente, aveți nevoie de exact aceleași date - precum adresa imobilului, calitatea de persoană fizică sau juridică a dobânditorului dreptului de proprietate etc. -, ca cele de care aveați nevoie până acum.'
  },
  {
    question: 'Cine poate folosi aplicația?',
    answer:
      'Întrucât dorim să eficientizăm procesul de calculare al valorilor imobiliare din studiile de piață și al taxelor notariale, aplicația poate fi folosită atât de către notar, cât și de către personalul angajat din cadrul biroului.'
  },
  {
    question: 'Cum mă asigur că rezultatul generat de aplicație este cel corect?',
    answer:
      'După fiecare set de date despre imobil care este introdus în aplicație, aceasta generează un rezultat. Modul în care s-au făcut toate calculele pentru a ajunge la acest rezultat este explicat în secțiunea "sumar", unde veți găsi toate datele extrase din studiile de piață și formulele pe baza cărora s-a generat rezultatul.'
  }
];

export const HomePage: React.FC<Props> = () => {

  return (
    <Page>
      <Helmet>
        <title>Acasă</title>
      </Helmet>

      <h1 className="text-3xl text-medium">Acasă</h1>

      <p className="text-lg mt-2 mb-10">Aici găsești principalele functionalități ale aplicației</p>

      <h1 className="text-center font-bold mb-1 text-lg color: text-red-700"> Momentan aplicația Notario folosește valorile din Studiile de Piață aferente anului 2023. În curând, platforma va fi actualizată conform Studiilor de Piață 2024. </h1>

      <div className="flex justify-center mb-12">
        <div className="lg:w-3/4">
          <div className="mt-12 md:mt-24">
            <div className="flex flex-col md:flex-row -m-6 justify-center items-center">
              {navigation.map((options) => (
                <NavigationCard key={options.title} {...options} className="m-6 max-w-[19rem] md:h-[24rem]" />
              ))}
            </div>
          </div>
          
          <div className='flex flex-col items-center mt-12'>
                <div className='flex justify-center mb-5'>  Oferiți-ne feedback! </div>
                <ButtonWithIcon className="flex max-w-[15rem] w-auto" onClick={openExternalSiteInNewTab} icon={EnvelopeIcon} variant="green"> Google Form </ButtonWithIcon>
          </div>

          <div className="flex flex-col mt-16 md:mt-18 md:flex-row">
            <div className="flex flex-col">
              <div className="text-semibold text-xl md:text-2xl">Întrebări frecvente</div>

              <dl className="mt-3 space-y">
                {faqs.map((faq, index) => (
                  <FaqItem key={index} item={faq} />
                ))}
              </dl>
            </div>

            {/* <div className="flex flex-col mt-12 md:mt-0 md:w-1/4">
              <div className="text-medium text-semibold text-xl md:text-base">Ai întâmpinat probleme?</div>

              <Link to={routes.feedback}>
                <ButtonWithIcon icon={EnvelopeIcon} className="mt-6">
                  Lasă-ne un feedback
                </ButtonWithIcon>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </Page>
  );
};
