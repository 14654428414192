import useSWR from 'swr';
import { useLocalStorage } from 'react-use';
import { AuthResponse, transactionsAccess } from '@/access';
import { AxiosError, isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/constants';


export const useLoadTransactionTypes = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken, removeAccessTokenData] = useLocalStorage<AuthResponse>('access-token');
  const [profileData, setProfileData, removeProfileData] = useLocalStorage('profile-data');


  const fetchTransactionTypes = async () => {
    try {
      const response = await transactionsAccess.getTransactionTypesList();
      return response;
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
          removeAccessTokenData();
          removeProfileData();
          navigate(routes.login);
        }
      }
      throw error;
    }
  };

  return useSWR('getTransactionTypesList', fetchTransactionTypes, {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
