import React from 'react';
import { useForm } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import { Checkbox } from '@/components/Checkbox';
import { DeleteEstateButton } from '@/components/DeleteEstateButton';
import { Form } from '@/components/Form';
import { Input } from '@/components/Input';
import { Listbox } from '@/components/Select/Listbox';
import { EstateTypeListboxItem, EstateTypesListbox } from '@/components/Selects/EstateTypesListbox';

import { GetCategoryWidgetsListResponse } from '@/access';
import { WidgetType, Widgets } from '@/domain';

import { ShareInput } from './ShareInput';

const widgetMapper = (name: string, index: number, widget: Widgets) => {
  const common = {
    id: `${widget.id}-${index}-estate-type`,
    name: `${name}.p${widget.id}`,
    ...(widget.label && { label: widget.label }),
    className: 'm-1'
  };

  const map = {
    integer: 'number',
    text: 'text'
  };

  switch (widget.type) {
    case WidgetType.Select:
      return (
        <Form.Field
          key={widget.name}
          as={Listbox}
          placeholder={widget.placeholder}
          options={widget.options}
          {...common}
        />
      );
    case WidgetType.Input:
      return (
        <Form.Field
          key={widget.name}
          as={Input}
          type={map[widget.data_type] ?? 'text'}
          placeholder={widget.placeholder}
          {...common}
        />
      );
    case WidgetType.Checkbox:
      return <Form.Field key={widget.name} as={Checkbox} type="checkbox" {...common} />;
  }
};

export interface Props {
  id: string;
  index: number;
  name: string;
  // TODO: Fix this type
  // @ts-ignore
  fields: FieldArrayRenderProps['fields'];
  values: { properties: any[] };
  categoryWidgets?: GetCategoryWidgetsListResponse;
  onDeleteEstateButtonClicked?: () => void;
}

export const EstateEvaluationDataField: React.FC<Props> = ({
  id,
  fields,
  name,
  index,
  values,
  categoryWidgets,
  onDeleteEstateButtonClicked
}) => {
  const form = useForm();
  const [showShareInput, setShowShareInput] = React.useState(false);

  const estateType = values.properties[index]?.estateType;
  const categoryWidget = categoryWidgets?.find(({ id }) => id === estateType?.id);

  const related = categoryWidgets?.find(({ id }) => id === categoryWidget?.related_category);

  return (
    <div className="flex items-center flex-wrap -m-1">
      <span className="text-gray-400 min-w-[12px] m-1 mr-2">{index + 1}</span>

      <Form.Field
        id={`${id}-${index}-estate-type`}
        name={`${name}.estateType`}
        as={EstateTypesListbox}
        options={categoryWidgets ?? []}
        placeholder="Selectează imobil"
        className="m-1"
        onChange={(estateType?: EstateTypeListboxItem & EstateTypeListboxItem[]) => {
          fields.update(index, { estateType });
        }}
      />

      {estateType && categoryWidget?.widgets.map((widget) => widgetMapper(name, index, widget))}

      {categoryWidget?.tool_tip && related && (
        <button
          type="button"
          className="h-[2.375rem] m-1 font-medium break-word text-main hover:text-main-dark"
          onClick={() => {
            fields.push({
              estateType: {
                id: related?.id,
                name: related?.name
              }
            });
          }}
        >
          {categoryWidget.tool_tip}
        </button>
      )}

      {categoryWidget?.show_surface_calculator && showShareInput && (
        <Form.Field
          id={`${id}-${index}-share`}
          name={`${name}.share`}
          as={ShareInput}
          type="text"
          placeholder="Parte / Total"
          className="m-1"
        />
      )}

      {categoryWidget?.show_surface_calculator && (
        <button
          type="button"
          className="h-[2.625rem] m-1 font-medium break-word text-main hover:text-main-dark"
          onClick={() => {
            if (showShareInput) form.change(`${name}.share`, undefined);

            setShowShareInput(!showShareInput);
          }}
        >
          {showShareInput ? 'Elimină cotă' : 'Calc. cotă (optional)'}
        </button>
      )}

      <div className="min-h-[2.625rem] flex justify-center items-center m-1">
        <DeleteEstateButton
          type="button"
          onClick={() => {
            if (showShareInput) setShowShareInput(false);

            if (onDeleteEstateButtonClicked) onDeleteEstateButtonClicked();

            fields.remove(index);
          }}
        />
      </div>
    </div>
  );
};
