import classNames from 'classnames';

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  className?: string;
}

export const Checkbox = ({ label, className, onChange, ...rest }: Props) => {
  return (
    <div className={classNames('relative flex items-start', className)}>
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className={`h-4 w-4 rounded text-main focus:ring-main 
          ${rest.id === "register-page-acceptedTermsAndConditions" && rest.checked === false ? "border-red-500" : "border-gray-300"}`}
          onChange={(event) => {
            // TODO: Check why react final form is triggering onChange even if type is checkbox
            // @ts-ignore
            if (onChange) onChange(event.target.checked);
          }}
          {...{ rest }}
        />
      </div>

      {label && (
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className={`font-medium 
          ${rest.id === "register-page-acceptedTermsAndConditions" && rest.checked === false ? "text-red-500" : "text-gray-700"}`}>
            {label}
          </label>
        </div>
      )}
    </div>
  );
};
