import { TrashIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react';

export interface DeleteEstateButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const DeleteEstateButton: React.FC<DeleteEstateButtonProps> = ({ className, ...rest }) => (
  <button className={classNames('group transition hover:pointer', className)} {...rest}>
    <TrashIcon className="w-5 h-5 text-gray-400 group-hover:text-red-600" />
  </button>
);
