import React from 'react';

export const useTimeoutTrigger = () => {
  const [display, setDisplay] = React.useState(false);

  const trigger = React.useCallback((ms = 3000) => {
    setDisplay(true);

    setTimeout(() => {
      setDisplay(false);
    }, ms);
  }, []);

  return React.useMemo(() => [display, trigger] as const, [display, trigger]);
};
