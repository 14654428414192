import { LockClosedIcon, KeyIcon, EnvelopeIcon, EyeIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import * as yup from 'yup';
import { InputWithValidationAndSVG } from '@/components/InputWithPhoto';
import { useAsyncFn, useLocalStorage } from 'react-use';

import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';
import { Form } from '@/components/Form';
import { InputWithValidation } from '@/components/Input';
import { AuthRequestBody } from '../../access/login-access';

import { enrollmentAccess, authAccess, profileAccess, setBearerToken, ProfileResponse } from '@/access';
import { AuthResponse } from '../../access/login-access';
import { Checkbox } from '@/components/Checkbox';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/constants/constants';
import ReactGA from "react-ga4";

export interface Props {
  id: string;
  className?: string;
}

export const LoginForm: React.FC<Props> = ({ id, className }) => { 

  useEffect(() => {
    removeAccessTokenData();
  }, []);
  const navigate = useNavigate();

  const [accessToken, setAccessToken, removeAccessTokenData] = useLocalStorage<AuthResponse>('access-token');
  const [profileData, setProfileData] = useLocalStorage('profile-data');
  const [temporaryAccessToken, setTemporaryAccessToken] = useState<AuthResponse>();
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>();


  const [, login] = useAsyncFn(
    async (logindata: AuthRequestBody) => {
      await authAccess.login(logindata).then(res => {
        setTemporaryAccessToken(res);
        setAccessToken(res);
        setInvalidCredentials(false);
      })
      .catch(() => {
        setTemporaryAccessToken(undefined)
        setInvalidCredentials(true);
      });
    },
    []
  );

  useEffect(() => {
    if (temporaryAccessToken) {
      setBearerToken(temporaryAccessToken.access);
      profileAccess.profileData()
        .then((res) => {
          setProfileData(res);
          ReactGA.set({ userId: res.id });
          navigate(routes.app);
        })
        .catch((er) => console.log(er));
    }
  }, [temporaryAccessToken]);


  return(
    <Form
      {...{ id }}
      schema={yup.object({
        username: yup.string().email().min(1).max(254).required(),
        password: yup.string().min(1).max(128).required(),
      })}
      className={classNames('flex flex-col w-full space-y-3', className)}
      onSubmit={async (values) => {

        await login(values);
        
      }}
    >
      {({ id, submitSucceeded }) => { 
        return (
          <>
            <Form.Field
              as={InputWithValidationAndSVG}
              id={`${id}-username`}
              name="username"
              type="text"
              placeholder="Adresa email"
              className="w-full relative"
              forwardIcon={true}
              iconType={'EnvelopeIcon'}
            />

            <Form.Field
              as={InputWithValidationAndSVG}
              id={`${id}-password`}
              name="password"
              placeholder="Parola"
              className="w-full relative"
              forwardIcon={true}
              iconType={'KeyIcon'}
              aditionalIcon={'EyeIcon'}
            />

          {invalidCredentials && 
          <div  style={{
            color: 'red',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 400,
            lineHeight: '20px',
            wordWrap: 'break-word',
            // textAlign: 'left',
          }}> Contul nu există, vă rugăm să vă inregistrați </div>}

            <div className="flex flex-row justify-between"></div>
            {/* <div className="flex flex-row justify-between">
                <Form.Field
                  as={Checkbox}
                  id={`${id}-persist-login`}
                  name="persist-login"
                  type="checkbox" 
                  className="w-full color: #374151;"
                  label="Tine-ma minte"
                  style={{
                    color: '#374151',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    lineHeight: '20px',
                    wordWrap: 'break-word'
                  }}
                />

              <span
                style={{
                  color: '#111827',
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  lineHeight: '20px',
                  wordWrap: 'break-word'
                }}
              >
                Ai uitat parola?
              </span>

            </div> */}

            <ButtonWithIcon
              icon={LockClosedIcon}
              type="submit"
              form={id}
              className="w-full"
            >
              Autentificare
            </ButtonWithIcon>
          </>
        )}
      }
    </Form>
  );
}