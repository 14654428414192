import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

import { Logo } from '@/components/Logo';

import { NavigationItem } from './Layout';
import { NavigationItems } from './NavigationItems';
import { SidebarContainer } from './SidebarContainer';

export interface Props {
  items: (NavigationItem | NavigationItem[])[];
}

export const MobileSidebar = ({ items }: Props) => {
  const { isOpen, close: onClose } = SidebarContainer.useContainer();

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-30 md:hidden" {...{ onClose }}>
        <div className="fixed inset-0 z-30 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full max-w-md flex-1 flex-col bg-main text-white focus:outline-none">
              <button onClick={onClose} className="absolute top-4 right-4">
                <XMarkIcon className="w-6 h-6" />
              </button>

              <div className="pt-12 flex justify-center">
                <Logo logoClassName="h-9 w-9" textClassName="text-medium" showText />
              </div>

              <div className="pt-5 pb-4">
                <NavigationItems onItemClick={onClose} {...{ items }} />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
