import { ArrowTrendingDownIcon, CalculatorIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { FeePage } from '@/pages/PropertyEvaluationAndFees/FeePage';
import { EvaluationPage } from '@/pages/PropertyEvaluationAndFees/PropertyEvaluationPage';

const TABS = [
  {
    title: 'Valoarea imobilului din studiul de piata',
    icon: ArrowTrendingDownIcon,
    path: '',
    element: <EvaluationPage />
  },
  { title: 'Calculare onorariu', icon: CalculatorIcon, path: 'taxe-notariale', element: <FeePage /> }
];

const TabsNavigation = () => {
  const isDesktop = useMedia('(min-width: 768px)');
  const isTablet = useMedia('(min-width: 480px)');
  const location = useLocation();

  const navigate = useNavigate();
  const { params } = useMatch('/home/evaluare-imobile/*') ?? {};

  const selectedIndex = TABS.findIndex(({ path }) => path === params?.['*']) ?? 0;

  const [max, setMax] = React.useState(location.state?.from === 'sidebar' ? 0 : selectedIndex);

  React.useEffect(() => {
    if (selectedIndex <= max) return;

    setMax(selectedIndex);
  }, [selectedIndex, max]);

  const tabs = React.useMemo(() => TABS.slice(0, max + 1), [TABS, max]);

  return (
    <>
      <div className="absolute inset-x-0 top-0 bg-main h-11 md:h-24">
        <div className="absolute bottom-0 left-0 flex">
          {tabs.map(({ title, path, icon: Icon }, index) => {
            const selected = index === selectedIndex;

            return (
              <div
                key={path}
                onClick={() => {
                  navigate(path);
                }}
                className={classNames(
                  'px-4 py-2 flex items-center text-center rounded-t-2xl hover:cursor-pointer md:px-10',
                  {
                    'bg-white font-semibold text-sm md:text-lg': selected,
                    'text-white font-medium shadow-tab-navigation text-sm md:text-base': !selected
                  }
                )}
              >
                {isDesktop ? title : selected && isTablet ? title : <Icon className="w-6 h-6" />}
              </div>
            );
          })}
        </div>
      </div>

      <div className="pt-12 md:pt-24">
        <Outlet />
      </div>
    </>
  );
};

export interface Props {}

const EvaluationAndFeesInner: React.FC<Props> = () => {

  return (
    <Routes>
      <Route element={<TabsNavigation />}>
        {TABS.map(({ path, element }) => (
          <Route key={path} {...{ element, path }} />
        ))}
      </Route>

      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export const EvaluationAndFees = () => <EvaluationAndFeesInner />;
