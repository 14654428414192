import useSWR from 'swr';
import { useLocalStorage } from 'react-use';
import { AuthResponse, widgetAccess } from '@/access';
import { AxiosError, isAxiosError } from 'axios';
import { routes } from '@/constants/constants';
import { useNavigate } from 'react-router-dom';

export interface Options {
  county_id?: number;
  area_id?: number;
}

export const useLoadCategoryWidgets = (options: Options) => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken, removeAccessTokenData] = useLocalStorage<AuthResponse>('access-token');
  const [profileData, setProfileData, removeProfileData] = useLocalStorage('profile-data');


  const key = options.county_id
    ? {
        county_id: options.county_id,
        ...(options.area_id && { area_id: options.area_id }),
        key: 'getCategoryWidgets',
      }
    : undefined;

  const fetchCategoryWidgets = async (key: any) => {
    try {
      const response = await widgetAccess.getCategoryWidgets(key);
      return response;
    }  catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
          removeAccessTokenData();
          removeProfileData();
          navigate(routes.login);
        }
      }
      throw error;
    }
  };

  return useSWR(key, fetchCategoryWidgets, {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
};
