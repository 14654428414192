import { api } from '@/util/networking';

export interface AuthResponse {
  refresh: string,
  access: string,
}

export interface AuthRequestBody {
    username: string,
    password: string,
}

export const login = (options: AuthRequestBody) => {
  return api.post<AuthResponse, AuthRequestBody>(
    '/v1/auth/token/',
    options
  );
};

export const authAccess = {
    login
};
