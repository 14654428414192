import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Dialog, Props as DialogProps } from '@/components/Dialog';
import { Form } from '@/components/Form';
import { Input } from '@/components/Input';
import { SubmitError } from '@/components/SubmitError';
import { TextArea } from '@/components/TextArea';

export interface Props extends DialogProps {
  onSubmit: (o: { contact_info: string; additional_note: string }) => void | Promise<void>;
}

export const EvaluationFeedbackDialog: React.FC<Props> = ({ onSubmit, ...props }) => {
  return (
    <Dialog {...props}>
      <Dialog.Title className="font-semibold mb-6">
      Ajutați-ne să devenim mai buni, ce sugestii aveți pentru Notario?
      </Dialog.Title>

      <Form
        id="evaluation-feedback-form"
        schema={yup.object({
          contact_info: yup.string().required(),
          additional_note: yup.string().required()
        })}
        onSubmit={(values) =>
          Promise.resolve()
            .then(() => onSubmit(values))
            .then(props.close)
        }
        className="space-y-4"
      >
        {({ id, submitError, valid }) => (
          <>
            <Form.Field
              as={Input}
              type="text"
              id={`${id}-contact-info`}
              name="contact_info"
              label="Informații de contact"
              placeholder="Nume complet / birou notarial"
            />

            <Form.Field
              as={TextArea}
              type="text"
              name="additional_note"
              id={`${id}-additional-note`}
              label="Descriere"
              placeholder="Ce putem îmbunătăți sau adăuga în cadrul platformei ori ce v-a surprins în mod plăcut?"
              className="h-40"
            />

            <SubmitError error={submitError} />

            <div className="flex justify-end">
              <Button type="submit" form={id} disabled={!valid}>
                Trimite recenzie
              </Button>
            </div>
          </>
        )}
      </Form>
    </Dialog>
  );
};
