import { FormSpy } from 'react-final-form';
import { useDebounce } from 'react-use';

export interface Props {
  debounce?: number;
  dependency?: unknown;
}

export const SubmitOnDependencyChange: React.FC<Props> = ({ debounce = 1000, dependency }) => {
  return (
    <FormSpy subscription={{ values: true, valid: true }}>
      {({ valid, form }) => {
        useDebounce(
          () => {
            if (valid) form.submit();
          },
          debounce,
          [dependency]
        );

        return <></>;
      }}
    </FormSpy>
  );
};
