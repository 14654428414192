import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as NavigationCardBgAsset } from './navigation-card-bg.svg';
import { ReactComponent as NavigationCardAsset } from './navigation-card.svg';

export interface Props {
  icon: React.ElementType;
  to: string;
  title: string;
  subtitle: string;
  className?: string;
}

export const NavigationCard = ({ icon: Icon, title, subtitle, className, ...rest }: Props) => (
  <Link
    className={classNames(
      'relative flex items-center px-3 py-4 rounded-2xl shadow-category-card group md:hover:-translate-y-3 md:transition md:flex-col md:py-12 md:px-6 md:text-center overflow-hidden group',
      className
    )}
    {...rest}
  >
    <Icon className="text-gray-400 w-8 h-8 mr-6 md:mr-0 md:w-12 md:h-12 md:mb-6 group-hover:text-main transition-colors" />

    <NavigationCardBackground className="hidden md:block w-full bottom-0 inset-x-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100" />

    <div className="relative md:mt-[5.625rem] z-10 md:group-hover:text-white">
      <div className="font-medium md:text-xl">{title}</div>

      <div className="mt-2 text-xs text-gray-500 md:group-hover:text-white md:mt-4 md:text-base">{subtitle}</div>
    </div>
  </Link>
);

interface NavigationCardBackgroundProps {
  className?: string;
}

const NavigationCardBackground: React.FC<NavigationCardBackgroundProps> = ({ className }) => (
  <div className={classNames('absolute', className)}>
    <NavigationCardBgAsset className="absolute bottom-0 right-0 w-2/3 h-auto text-main" />

    <NavigationCardAsset className="absolute bottom-0 inset-x-0 w-full h-auto text-main" />
  </div>
);
