import { Dialog, Props as DialogProps } from '@/components/Dialog';
import { FeeJustificationTable } from '@/components/FeeJustificationTable';

import { ComputeFeesValueResponse } from '@/access';

export interface Props extends DialogProps {
  value: ComputeFeesValueResponse;
}

export const FeeJustificationDialog: React.FC<Props> = ({ value, ...props }) => {
  return (
    <Dialog {...props} className="flex py-12 md:w-[90vw]">
      <div className="overflow-scroll flex-1">
        <FeeJustificationTable {...{ value }} />
      </div>
    </Dialog>
  );
};
