import { LoginForm } from "./LoginForm";
import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { Fee } from '@/components/Fee';
import { ButtonWithIcon } from "@/components/Button/ButtonWithIcon";
import { BanknotesIcon, KeyIcon, EnvelopeIcon, EyeIcon } from '@heroicons/react/24/solid';
import { Navigate, useNavigate } from 'react-router-dom';
import { CONTACT_EMAIL_ADDRESS, CONTACT_PHONE_NUMBER, routes } from '../../constants/constants'; 

export interface Props {}

export const LoginPage: React.FC<Props> = () => {

  const navigate = useNavigate();

  return (
    <div 
    className='md:pt-[8vw] sm:pt-[30vw]'
    style={{
    height: '100%',
    background: '#314B3C'
    }}> 

    <div 
    className='flex flex-col items-center sm:pb-10 sm:pt-10 md:pt-20 md:relative md:pb-20 md:w-1/2 md:ml-[28vw] ml-[10vw]'
    style={{
      backgroundColor: '#F3F4F6',
      borderRadius: '24px',
      maxWidth: '700x'
    }}> 

      <div style={{
        color: '#374151',
        fontSize: '30px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '36px',
        wordWrap: 'break-word',
        paddingBottom: '40px'
      }}>
        Accesați contul dvs.
      </div>

      <div className="flex justify-center text-center items-center md:w-[75%] sm:w-[80%]">
          <LoginForm id='login-page'/>
      </div>
          
      <div
       className="flex justify-center text-center mt-4 items-center md:w-[75%] sm:w-[85%]"
       style={{
          fontSize: '16px',
        }}
      > 
        Acum vă puteți crea un cont pentru a beneficia de funcționalitățile viitoare!  
      </div>

      <div 
      className="flex justify-center text-center items-center"
      style={{
          display: 'flex',
           justifyContent: 'space-around',
           flexDirection: 'row',
           marginTop: '10px',
          }}>  
        <span
          style={{
            color: '#4B5563',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 400,
            lineHeight: '20px',
            marginRight: '5px'
          }}
        >
          Nu ai un cont?
        </span>

        <span
          className="text-green-600 text-base font-semibold leading-5 cursor-pointer"
          style={{
          //   color: '#527A62',
            fontSize: '16px',
          //   fontFamily: 'Poppins',
          //   fontWeight: 600,
          //   lineHeight: '20px',
          }}
          onClick={() => navigate(routes.register)}
        >
          Inregistreaza-te acum
        </span>
      </div>

      <div className="flex justify-center text-center items-center w-full px-6 md:px-16 pt-5">
         <ButtonWithIcon
            icon={BanknotesIcon}
            variant="white"
            className="w-full"
            onClick={() => navigate(routes.app)}
          >
            Acces fără cont, limitat
        </ButtonWithIcon>
        
      </div>

      <div className="text-center md:w-[75%] sm:w-[80%] pt-5"
          style={{
            fontSize: '14px',
          }}>
          Dacă întâmpinați probleme tehnice, ne puteți contacta la 
          <a className="text-green-600 text-base font-semibold"
          href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>{` ${CONTACT_EMAIL_ADDRESS} `}</a>
          sau la 
          <a className="text-green-600 text-base font-semibold"
          href={`tel:${CONTACT_PHONE_NUMBER}`}>{` ${CONTACT_PHONE_NUMBER}`}</a>
      </div>

    </div>
    </div>
  );
};
