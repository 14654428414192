import * as Sentry from '@sentry/react';
import React from 'react';

import {Button} from './Button';
import {CONTACT_PHONE_NUMBER} from "@/constants";

export const ErrorBoundary = ({children}: React.PropsWithChildren) => (
    <Sentry.ErrorBoundary
        showDialog={false}
        fallback={({eventId, resetError}) => (
            <div className="w-full min-h-screen flex items-center justify-center relative">
                <div className="max-w-2xl lg:max-w-3xl">
                    <div className="text-headline-1 mb-6 font-medium">Oops! Am intampinat o problema.</div>

                    <div className="mb-4">Echipa noastra a fost notificata automat.</div>

                    <div>Ne puteti contacta la:</div>
                    <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER}</a>
                    <div>cu identificatorul de eveniment:</div>
                    <div className="mb-4">{eventId}</div>

                    <Button onClick={resetError} variant="green" className="px-4">
                        Reincearca
                    </Button>
                </div>
            </div>
        )}
    >
        {children}
    </Sentry.ErrorBoundary>
);
