import { Link } from 'react-router-dom';

import { NavigationItem } from '@/components/Layout';

import { NavigationItem as NavigationItemComponent } from './NavigationItem';
import { AuthResponse, ProfileResponse } from '@/access';
import { useLocalStorage } from 'react-use';

export interface Props {
  items: (NavigationItem | NavigationItem[])[];
  onItemClick?: () => void;
}

const isNavigationItem = (item: NavigationItem | NavigationItem[]): item is NavigationItem => !Array.isArray(item);

export const NavigationItems = ({ items, onItemClick }: Props) => {
  
  const [_, __, removeAccessTokenData] = useLocalStorage<AuthResponse>('access-token');
  const [profileData, setProfileData, removeProfileData] = useLocalStorage('profile-data');
  
  const clearUserData = () => {
    removeProfileData();
    removeAccessTokenData();
  }

  return (
    <nav aria-label="Sidebar" className="mt-5 space-y-7 text-white md:space-y-4">
      {items.map((item, index) => {
        if (isNavigationItem(item)) return <NavigationItemComponent key={item.name} {...{ item, onItemClick }} />;

        return (
          <div
            key={`section-${index}`}
            className="relative space-y-7 py-4 md:shadow-none md:space-y-4 before:absolute before:inset-0 before:shadow-navigation-section before:mr-1"
          >
            {item.map((item) => {
              return <NavigationItemComponent key={item.name} {...{ item, onItemClick, clearUserData }} />;
            })}
          </div>
        );
      })}
    </nav>
  );
};
