import { api } from '@/util/networking';

export interface RegisterResponse {
  refresh: string,
  access: string,
}

export interface RegisterRequestBody {
    password: string,
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    office_name: string, 
}

export const registration = (options: RegisterRequestBody) => {
  return api.post<RegisterResponse, RegisterRequestBody>(
    '/v1/users/register/',
    options
  );
};

export const userRegistrationAccess = {
    registration
};
