export interface Item<K> {
  id: K;
  name: string;
}

export interface SelectProps<T, V = T> {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  options: T[];
  value?: V | V[];
  onChange: (value?: V & V[]) => void;
  className?: string;
  isLoading?: boolean;
}

export const isMultiple = <T>(item: T | T[], multiple?: boolean): item is T[] => multiple || Array.isArray(item);

export const getDisplayName = <K, T extends Item<K>>(value?: T | T[], multiple?: boolean) => {
  if (!isMultiple(value, multiple)) return value?.name;

  return value?.length ? value.map(({ name }) => name).join(', ') : undefined;
};
