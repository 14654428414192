import { Dialog as HeadlessUiDialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

import { DialogCloseButton } from './DialogCloseButton';

export interface Props {
  isOpen: boolean;
  close: () => void;
  className?: string;
}

const Dialog = ({ isOpen, close, className, children }: React.PropsWithChildren<Props>) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <HeadlessUiDialog onClose={close} className="relative z-40">
        <Transition.Child
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HeadlessUiDialog.Overlay className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <Transition.Child
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 flex items-center justify-center p-4"
        >
          <HeadlessUiDialog.Panel
            className={classNames('relative bg-white rounded-md w-[80vw] max-h-[80vh] md:w-1/2 p-8', className)}
          >
            <DialogCloseButton className="absolute top-3 right-8" onClick={close} />
            {children}
          </HeadlessUiDialog.Panel>
        </Transition.Child>
      </HeadlessUiDialog>
    </Transition>
  );
};

Dialog.Title = HeadlessUiDialog.Title;
Dialog.Description = HeadlessUiDialog.Description;

export { Dialog };
