import classNames from 'classnames';

export interface Props {
  id?: string;
  as?: React.ElementType;
  className?: string;
}

export const PaddedComponent = ({ as: Component = 'div', className, ...rest }: React.PropsWithChildren<Props>) => {
  return <Component className={classNames('px-4 sm:px-12 md:px-20 lg:px-36 xl:px-60', className)} {...rest} />;
};
