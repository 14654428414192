import useSWR from 'swr';
import { useLocalStorage } from 'react-use';
import { AuthResponse, transactionsAccess } from '@/access';
import { AxiosError, isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/constants';

export interface Options {
  transaction_type_id?: number;
}

export const useLoadFeeWidgets = (options: Options) => {

  const navigate = useNavigate();
  const [accessToken, setAccessToken, removeAccessTokenData] = useLocalStorage<AuthResponse>('access-token');
  const [profileData, setProfileData, removeProfileData] = useLocalStorage('profile-data');

  const key = options.transaction_type_id
    ? { transaction_type_id: options.transaction_type_id, key: 'getCategoryWidgets' }
    : undefined;

  return useSWR(
    key,
    async ({ key, ...options }) => {
      try {
        const response = await transactionsAccess.getFeeWidgets(options);
        return response;
      } catch (error) {
        if (isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
            removeAccessTokenData();
            removeProfileData();
            navigate(routes.login);
          }
        }
        throw error;
      }
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
};
