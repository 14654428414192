import { EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

import { PaddedComponent } from '@/components/PaddedComponent';

import { CONTACT_EMAIL_ADDRESS, CONTACT_PHONE_NUMBER } from '@/constants/constants';

import { ReactComponent as ContactSectionAsset } from './contact-section.svg';

const contacts = [
  {
    label: 'Adresă de email',
    icon: EnvelopeIcon,
    text: <a href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>{CONTACT_EMAIL_ADDRESS}</a>
  },
  {
    label: 'Număr de telefon',
    icon: PhoneIcon,
    text: <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER}</a>
  },
  {
    label: 'Adresă',
    icon: MapPinIcon,
    text: (
      <a href="https://www.google.com/maps/place/Cluj-Napoca" target="_blank" rel="noopener noreferrer">
        Cluj-Napoca | România
      </a>
    )
  }
];

export interface Props {}

export const ContactSection: React.FC<Props> = () => {
  return (
    <PaddedComponent as="section" id="contact" className="relative flex py-44 md:justify-end ">
      <ContactSectionAsset className="hidden w-[35vw] absolute left-0 bottom-0 md:inline-block " />

      <div className="w-full md:w-1/2">
        <h1 className="text-2xl text-center mb-8 md:text-left md:text-6xl md:mb-20">Unde ne găsiți?</h1>

        <div className="flex flex-col space-y-10">
          {contacts.map((a, index) => (
            <ContactDetails key={index} {...a} />
          ))}
        </div>
      </div>
    </PaddedComponent>
  );
};

interface SectionProps {
  label: string;
  icon: React.ElementType;
  text: React.ReactNode;
  className?: string;
}

const ContactDetails: React.FC<SectionProps> = ({ label, icon: Icon, text, className }) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      <span className="text-xs text-gray-500 mb-5 md:text-xl ">{label}</span>

      <div className="flex items-center">
        <Icon className="w-5 h-5 text-main mr-5 md:w-6 md:h-6" />

        <span className="text-sm md:text-xl">{text}</span>
      </div>
    </div>
  );
};
