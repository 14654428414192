import { Transition } from '@headlessui/react';
import {
  ArrowTrendingDownIcon,
  CalculatorIcon,
  CurrencyEuroIcon,
  EnvelopeIcon,
  HomeIcon,
  UserCircleIcon,
  LockClosedIcon,
  AdjustmentsHorizontalIcon,
  ArrowLongLeftIcon,
  ArrowLeftOnRectangleIcon
} from '@heroicons/react/20/solid';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocalStorage, useMedia } from 'react-use';

import { useOpen } from '@/hooks/useOpen';
import { FeePage } from '@/pages/FeePage';
import { FeedbackPage } from '@/pages/FeedbackPage';
import { HomePage } from '@/pages/HomePage';
import { ProfilePage } from '@/pages/ProfilePage';
import { EvaluationAndFees } from '@/pages/PropertyEvaluationAndFees';
import { EvaluationPage } from '@/pages/PropertyEvaluationAndFees/PropertyEvaluationPage';
import { RatesPage } from '@/pages/RatesPage';

import { Content } from './Content';
import { DekstopSidebar } from './DesktopSidebar';
import { MobileSidebar } from './MobileSidebar';
import { MobileTopNavigation } from './MobileTopNavigation';
import { SidebarContainer } from './SidebarContainer';
import { EditProfilePage } from '@/pages/EditProfilePage';
import { LandingPage } from '@/pages/LandingPage';
import { AuthResponse, ProfileResponse, setBearerToken } from '@/access';
import { routes } from '@/constants';

export interface NavigationItem {
  name: string;
  to: string;
  end?: boolean;
  icon: React.ElementType;
  logout?: boolean;
  redirect?: boolean;
}

const InnerLayout = () => {
  const [profileData] = useLocalStorage<ProfileResponse>('profile-data');
  const [accessToken] = useLocalStorage<AuthResponse>('access-token');

  useEffect(() => {
    if(accessToken !== undefined) {
    setBearerToken(accessToken.access);
    }
  }, [])

  function isProfileDataAndAccessTokenValid() {
    if (profileData !== undefined && profileData !== null && accessToken !== undefined && accessToken !== null){
      return '/home/profil';
    } else {
      return routes.register;
    }
  }

  const accountFullName =
  profileData !== undefined || null ?
  `${profileData?.first_name} ${profileData?.last_name}` : null;
  const isDesktop = useMedia('(min-width: 768px)');
  const { isOpen, open } = SidebarContainer.useContainer();
  const navigation = React.useMemo<(NavigationItem | NavigationItem[])[]>(
    () => {
      const items = [
        { name: accountFullName !== null ? accountFullName : 'Profil', icon: UserCircleIcon, end: true, to: isProfileDataAndAccessTokenValid() },
        { name: 'Acasa', to: routes.app, icon: HomeIcon, end: true },
        [
          {
            name: isDesktop ? 'Valoarea imobilului' : 'Valoarea imobilului din studiul de piață',
            to: 'evaluare-imobile',
            icon: ArrowTrendingDownIcon,
          },
          { name: 'Taxe notariale', to: 'taxe-notariale', icon: CalculatorIcon },
          { name: 'Feedback', icon: EnvelopeIcon, end: true, to: '', redirect: true },
          // { name: 'Logout', icon: ArrowLeftOnRectangleIcon, end: true, to: '/login', logout: true },
          // { name: 'Tarife notariat', href: routes.rates, icon: CurrencyEuroIcon }
        ],
      ];
  
      if (accessToken === undefined) {
        return items.map((item) => {
          if (Array.isArray(item)) {
            return item.filter((subItem) => subItem.name !== 'Logout');
          }
          return item;
        });
      }
  
      return items;
    },
    [isDesktop, accessToken]
  );
  

  return (
    <div className="relative flex h-full">
      <Transition
        show={isOpen}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-30 absolute inset-0 bg-black"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-30 absolute inset-0 bg-black"
        leaveTo="opacity-0"
        className="z-20"
        appear
      />

      {!isDesktop && <MobileSidebar items={navigation} />}

      {isDesktop && <DekstopSidebar items={navigation} />}

      <div className="flex min-w-0 flex-1 flex-col overflow-hidden -ml-[0.313rem]">
        {!isDesktop && <MobileTopNavigation onMenuClick={open} />}

        <Content>
          <Routes>
            <Route path="/" element={<HomePage />} />

             <Route path="/profil/editare" element={<ProfilePage />} />
             <Route path={routes.profile} element={<ProfilePage />} />

            {/* <Route path={routes.feedback} element={<FeedbackPage />} /> */}

            <Route path="/evaluare-imobile/*" element={<EvaluationAndFees />} />
            <Route path="/taxe-notariale" element={<FeePage />} />

            {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
            {/* <Route path="/app" element={<Navigate to="/home" replace />} /> */}

          </Routes>
        </Content>
      </div>
    </div>
  );
};

export const Layout = () => (
  <SidebarContainer.Provider>
    <InnerLayout />
  </SidebarContainer.Provider>
);
