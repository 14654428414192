import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export interface Item<K> {
  id: K;
  name: string;
}

export interface Props<K, V extends Item<K> = Item<K>> {
  value: V;
  disabled?: boolean;
}

export const RadioGroupOption = <V,>({ value }: Props<V>) => (
  <RadioGroup.Option value={value.id}>
    {({ checked }) => (
      <div className={classNames('flex items-center hover:cursor-pointer')}>
        <div className={classNames('h-4 w-4 rounded-full border border-solid', { 'border-main border-4': checked })} />

        <span className="ml-2">{value.name}</span>
      </div>
    )}
  </RadioGroup.Option>
);
