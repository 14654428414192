import React from 'react';
import { useList } from 'react-use';
import { createContainer } from 'unstated-next';
import { v4 as uuid } from 'uuid';

interface Item {
  key: string;
  message: string;
  show?: boolean;
}

const useNotifications = () => {
  const [items, actions] = useList<Item>([]);

  const push = React.useCallback((message: string, timeout = 2000) => {
    const key = uuid();

    actions.push({ key, message, show: true });

    setTimeout(() => {
      actions.updateFirst((item) => item.key === key, { key, message, show: false });

      setTimeout(() => {
        actions.filter((item) => item.key !== key);
      }, timeout);
    }, timeout);
  }, []);

  return { items, push };
};

export const NotificationContainer = createContainer(useNotifications);
