import { Input, Props as InputProps } from './Input';

interface Share {
  part?: number | string;
  total?: number | string;
}

export interface Props extends Omit<InputProps, 'value' | 'onChange'> {
  value?: Share;
  onChange: (value?: Share) => void;
}

export const ShareInput: React.FC<Props> = ({ value, onChange, ...rest }) => {
  return (
    <Input
      onChange={(event) => {
        const normalized = event.target.value.replace(/[^[0-9/]/, '');

        if (!normalized) return onChange();

        const [part, total] = normalized.split('/');

        if (!part) return onChange();

        const value = {
          ...(part !== undefined && { part: part ? parseInt(part) : '' }),
          ...(total !== undefined && { total: total ? parseInt(total) : '' })
        };

        if (onChange) return onChange(value);
      }}
      value={`${value?.part ?? ''}${value?.total !== undefined ? `/${value.total}` : ''}`}
      {...rest}
    />
  );
};
