import { PaddedComponent } from '@/components/PaddedComponent';

import { ReactComponent as BackgroundAsset } from './background.svg';
import { ReactComponent as LaptopAsset } from './laptop.svg';

export interface Props {}

export const VideoSection: React.FC<Props> = () => {
  return (
    <section className="py-16 md:pt-20 md:mt-20 lg:pt-32 md:relative md:z-20">
      <PaddedComponent>
        <h1 className="text-2xl text-center mb-5 md:text-4xl"> Vreți să vedeți mai concret în ce constă Notario?</h1>

        <p className="text-center mb-16 text-gray-500 md:text-2xl md:mb-28">
          Mai jos găsiți un video explicativ în care prezentăm funcționalitățile aplicației
        </p>
      </PaddedComponent>

      <div className="relative">
        <div className="hidden md:relative md:inline-block md:w-full md:pt-8">
          <LaptopAsset className="absolute top-0 left-1/2 -translate-x-1/2 w-2/3" />

          <BackgroundAsset />
        </div>

        <iframe
          src="https://www.youtube.com/embed/x7ZsI8RJVUo?si=n13YA1Jkk2-5wogu"
          title="Notario Application Overview"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full h-[50vw] md:w-[32.5vw] md:h-[21vw] md:absolute md:top-1 lg:top-3 xl:top-4 md:left-[50.5%] md:-translate-x-1/2"
        />
      </div>
    </section>
  );
};
