import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import classNames from 'classnames';
import { LockClosedIcon } from '@heroicons/react/24/solid';

import { Form } from '@/components/Form';
import { InputWithValidationAndSVG } from '@/components/InputWithPhoto';
import { Checkbox } from '@/components/Checkbox';
import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';
import { userRegistrationAccess, RegisterRequestBody,  enrollmentAccess, authAccess, AuthResponse, profileAccess, setBearerToken, AuthRequestBody,  } from '@/access';
import { useAsyncFn, useLocalStorage, useStartTyping } from 'react-use';
import { bool } from 'prop-types';
import { openTC, routes } from '@/constants';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";


export interface Props {
  id: string;
  className?: string;
}

export const RegisterForm: React.FC<Props> = ({id, className}) => {

  const navigate = useNavigate();
  const [profileData, setProfileData] = useLocalStorage('profile-data');
  const [existingAccountError, setExistingAccountError] = useState<boolean>(false);
  const [registrationError, setRegistrationError] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useLocalStorage<AuthResponse>('access-token');

  useEffect(() => {
    if(accessToken !== undefined) {
      setBearerToken(accessToken.access);
      navigate(routes.app);
    }
  }, [accessToken]);

  const [, register] = useAsyncFn(
    async (registerdata: RegisterRequestBody) => {
      await userRegistrationAccess.registration(registerdata).then(res => {
        setAccessToken(res);
        setExistingAccountError(false);
        setRegistrationError(false); 
        setBearerToken(res.access);
        profileAccess.profileData()
        .then((res) => {
          setProfileData(res);
          ReactGA.set({ userId: res.id });
          navigate(routes.app);
        })
        .catch((er) => {
          setRegistrationError(true); 
          console.log(er)
        });
      })
      .catch((er) => {
        console.log(er);
        setExistingAccountError(true);
      });
    },
    []
  );

  return (
    <Form
      {...{id}}
      schema={yup.object({
        first_name: yup.string().min(2).max(128).required(),
        last_name: yup.string().min(2).max(128).required(),
        email: yup.string().email().required(),
        password: yup.string().min(6).max(128).required(),
        confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match").required(),
        office_name: yup.string().min(2).max(128).required(),
        phone_number: yup.string().matches(new RegExp('^(?:\\+?40|0040|0)?7\\d{8}$')).required(),
        acceptedTermsAndConditions: yup.bool().isTrue().required()
      })}
      className={classNames('flex flex-col w-full', className)}
      onSubmit={async (values) => {
        const { confirmPassword, ...resultObject } = values;
        await register(resultObject);
      }}
    >
      {({id, submitSucceeded}) => (
        <>
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-firstName`}
            name="first_name"
            type="text"
            placeholder="Prenume"
            className="w-full relative pb-2"
            forwardIcon={true}
            iconType={'UserIcon'}
          />
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-lastName`}
            name="last_name"
            type="text"
            placeholder="Nume"
            className="w-full relative pb-2"
            forwardIcon={true}
            iconType={'UserIcon'}
          />
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-email`}
            name="email"
            type="text"
            placeholder="Adresă email"
            className="w-full relative pb-2"
            forwardIcon={true}
            iconType={'EnvelopeIcon'}
          />
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-notaryOffice`}
            name="office_name"
            type="text"
            placeholder="Birou notarial"
            className="w-full relative pb-2"
            forwardIcon={true}
            iconType={'BuildingOffice2Icon'}
          />
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-phone`}
            name="phone_number"
            type="text"
            placeholder="Telefon"
            className="w-full relative pb-2"
            forwardIcon={true}
            iconType={'DevicePhoneMobileIcon'}
          />
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-password`}
            name="password"
            placeholder="Parolă"
            className="w-full relative pb-2"
            forwardIcon={true}
            iconType={'KeyIcon'}
            aditionalIcon={'EyeIcon'}
          />
          <Form.Field
            as={InputWithValidationAndSVG}
            id={`${id}-confirmPassword`}
            name="confirmPassword"
            placeholder="Repetare parolă"
            className="w-full relative"
            forwardIcon={true}
            iconType={'KeyIcon'}
            aditionalIcon={'EyeIcon'}
          />

          {existingAccountError && <div
          className="mt-3 text-left"
          style={{
            color: 'red',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 400,
            lineHeight: '20px',
            wordWrap: 'break-word',
            // textAlign: 'left',
          }}
          >
          
            User existent, va rugam sa va logati.
          </div>}

          {registrationError && <div
          className="mt-3 text-left"
          style={{
            color: 'red',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 400,
            lineHeight: '20px',
            wordWrap: 'break-word',
          }}
          >
            Inregistrarea nu a fost cu succes, va rugam incercati iar!
          </div>}
          

          <div className="mt-6 flex flex-row">
            <Form.Field
              as={Checkbox}
              id={`${id}-acceptedTermsAndConditions`}
              name="acceptedTermsAndConditions"
              type="checkbox"
              label='Am citit și sunt de acord cu'
            />
            <span className="hover:underline hover:cursor-pointer ml-1 text-sm leading-5 break-words">
              <a onClick={openTC}>
              <strong>Termeni și Condiții</strong>
              </a>
            </span>
          </div>

          <ButtonWithIcon
            icon={LockClosedIcon}
            type="submit"
            iconPosition="before-text"
            form={id}
            className="mt-6 w-full"
          >
            Înregistrează-te
          </ButtonWithIcon>
        </>
      )}

    </Form>);
}
