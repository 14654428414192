import classNames from 'classnames';
import { CalculatorIcon } from '@heroicons/react/24/solid';

import { PaddedComponent } from '@/components/PaddedComponent';

import { EnrollmentForm } from '@/pages/LandingPage/EnrollmentForm';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as HeroAsset } from './hero.svg';
import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';

export interface Props {}

export const HeroSection: React.FC<Props> = () => {

  const navigate = useNavigate();

  return (
    <section className="relative flex flex-col py-16 sm:py-18 md:py-20">
      <PaddedComponent className="w-full md:w-3/5 text-center md:text-left md:relative md:z-20">
        <h1 className="text-2xl mb-5 md:text-6xl md:mb-8">
          De ce nu ar fi mai <span className="text-main font-semibold">simplu?</span>
        </h1>

        <p className="mb-16 text-gray-500 md:text-xl">
          Notario automatizează procesul de calculare al valorilor imobiliare din studiile de piață și al taxelor
          aferente procedurilor notariale
        </p>
      </PaddedComponent>

      <div className="flex justify-end md:absolute md:right-0 md:top-2/3 md:-translate-y-1/2">
        <Hero className="mb-16 max-w-[375px] sm:max-w-[67%] md:max-w-[50vw]" />
      </div>

      <PaddedComponent className="w-full md:w-3/5 text-center md:text-left md:relative md:z-20">
        <p className="text-sm mb-5">
          Puteți aplica aici pentru a primi cont personalizat
        </p>

        <EnrollmentForm id="hero-enrollment" className="mb-4" />

        <p className="text-xs text-gray-500 md:text-sm">
          Aplicația este în permanentă dezvoltare. Adăugăm în continuare funcționalități noi și îmbunătățiri pentru a
          face cât mai ușor procesul de calculare al valorilor imobiliare și al taxelor notariale
        </p>
        
        <ButtonWithIcon variant="green" icon={CalculatorIcon} style={{fontSize: '16px',}} className="w-full mt-5 py-3 sm:py-2 md:py-3" slim onClick={() => navigate('/')}>
              Accesează acum aplicația
        </ButtonWithIcon>
      </PaddedComponent>
    </section>
  );
};

interface HeroProps {
  className?: string;
}

const Hero: React.FC<HeroProps> = ({ className }) => (
  <div className={classNames('relative', className)}>
    <HeroAsset className="relative w-full h-auto z-10" />

    <div className="absolute inset-0 bg-gradient-to-l from-transparent to-main-light blur-2xl opacity-90" />
  </div>
);
