import * as Sentry from '@sentry/react';

import { ENVIRONMENT, RELEASE, SENTRY_DSN } from '@/config';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: ENVIRONMENT === 'production' ? 0.3 : 1.0,
    environment: ENVIRONMENT,
    release: RELEASE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}
