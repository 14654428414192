import { NotificationContainer } from './NotificationContainer';
import { NotificationItem } from './NotificationItem';

export const NotificationPortal = () => {
  const notifications = NotificationContainer.useContainer();

  return (
    <div className="absolute top-10 md:top-0 right-0 pt-4 pr-4 z-50 space-y-2">
      {notifications.items.map(({ message, show, key }) => (
        <NotificationItem key={key} {...{ message, show }} />
      ))}
    </div>
  );
};
