import React from 'react';
import { useDebounce } from 'react-use';

import { Combobox, Props as ComboboxProps } from '@/components/Select/Combobox';

import { AreaListItem } from '@/domain';
import { Options, useLoadAreas } from '@/hooks/useLoadAreas';

export interface Props extends Omit<ComboboxProps<AreaListItem>, 'options' | 'query' | 'onQueryChange'>, Options {}

export const AreasLoadableCombobox = ({ disabled, county_id, settlement_id, street_id, ...rest }: Props) => {
  const [query, setQuery] = React.useState('');
  const [debouncedQuery, setDebouncedQuery] = React.useState(query);

  useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  const areas = useLoadAreas({ county_id, settlement_id, name: debouncedQuery });

  return (
    <Combobox
      options={areas.data ?? []}
      disabled={areas.isLoading || disabled}
      query={query}
      onQueryChange={setQuery}
      inputAppearance="accent"
      {...rest}
    />
  );
};
