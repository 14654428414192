import { RadioGroup as HeadlessUiRadioGroup } from '@headlessui/react';

import { Item, RadioGroupOption } from './RadioGroupOption';

export interface Props<K, V extends Item<K> = Item<K>> {
  value: V;
  onChange: () => void;
  options: V[];
  label?: string;
}

export const RadioGroup = <K, V extends Item<K> = Item<K>>({ label, options, ...props }: Props<K, V>) => {
  return (
    <HeadlessUiRadioGroup {...props}>
      {label && (
        <HeadlessUiRadioGroup.Label className="text-sm font-medium text-gray-500">{label}</HeadlessUiRadioGroup.Label>
      )}

      <div className="flex space-x-8">
        {options.map((option) => (
          <RadioGroupOption key={String(option.id)} value={option} />
        ))}
      </div>
    </HeadlessUiRadioGroup>
  );
};
