import classNames from 'classnames';
import React from 'react';
import { useHoverDirty } from 'react-use';

import { Logo } from '@/components/Logo';

import { NavigationItem } from './Layout';
import { NavigationItems } from './NavigationItems';
import { SidebarContainer } from './SidebarContainer';

export interface Props {
  items: (NavigationItem | NavigationItem[])[];
}

export const DekstopSidebar = ({ items }: React.PropsWithChildren<Props>) => {
  const { isOpen, set } = SidebarContainer.useContainer();
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);

  React.useEffect(() => {
    set(isHovering);
  }, [isHovering]);

  return (
    <div className="relative hidden w-14 h-full md:flex md:flex-shrink-0 z-30">
      <div ref={ref} className={classNames('transition-width duration-150 absolute inset-0 w-14', isOpen && 'w-64')}>
        <div className="absolute inset-0 bg-main mr-1" />

        <div className="absolute inset-0 flex-col ">
          <div className="relative flex h-full min-h-0 flex-1 flex-col">
            <div className="flex-1 z-10">
              <div className="flex items-center justify-center py-8">
                <Logo logoClassName="h-8 w-8" textClassName="text-medium" showText={isOpen} />
              </div>

              <NavigationItems {...{ items }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
