import { api } from '@/util/networking';

import { FeeWidgets, TransactionTypeListItem } from '@/domain';

export type GetTransactionTypesListResponse = TransactionTypeListItem[];

const getTransactionTypesList = () => {
  return api.get<GetTransactionTypesListResponse>('/v1/transactions/transaction-types/');
};

export type GetFeeWidgetsListResponse = FeeWidgets[];

export interface GetFeeWidgetsListQuery {
  transaction_type_id: number;
}

const getFeeWidgets = (options: GetFeeWidgetsListQuery) => {
  return api.get<GetFeeWidgetsListResponse>('/v1/transactions/fee-widgets/', options);
};

interface ContractPart {
  name: string;
  taxes: {
    name: string;
    value: number;
  }[];
  total: number;
}

export interface ComputeFeesValueResponse {
  agreed_value_tax: {
    minimum_tax_value: number;
    agreed_value: number;
  };
  parts: ContractPart[];
  total_price: number;
  justification: {
    fee: string;
    percentage: string;
    value: string;
    fixed_fee: string;
    calculation: string;
    total: string;
  }[];
}

export interface ComputeFeesValueRequestBody {
  transaction_type_id: number;
  minimum_tax_value: number;
  fee_widget_answers: {
    fee_id: number;
    widgets: {
      widget_id: number;
      value: number | string;
    }[];
  }[];
}

export const computeFeesValue = (options: ComputeFeesValueRequestBody) => {
  return api.post<ComputeFeesValueResponse, ComputeFeesValueRequestBody>(
    '/v1/transactions/fee-taxes/calculate/',
    options
  );
};

export const transactionsAccess = {
  computeFeesValue,
  getTransactionTypesList,
  getFeeWidgets
};
