import useSWR from 'swr';

import { placesAccess } from '@/access';

export interface Options {
  county_id?: number;
  street_id?: number;
  settlement_id?: string;
  name?: string;
}

export const useLoadAreas = (options: Partial<Options>) => {
  const key = options.county_id && options.settlement_id ? { ...options, key: 'getAreasList' } : undefined;

  return useSWR(
    key,
    ({ key, settlement_id, ...options }) => {
      const [type, id] = settlement_id?.split('-') ?? [];

      return placesAccess.getAreasList({
        ...options,
        ...(type === 'city' && { city_id: id })
      });
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
};
