import { Bars3BottomRightIcon } from '@heroicons/react/20/solid';

export interface Props {
  onMenuClick: () => void;
}

export const MobileTopNavigation = ({ onMenuClick }: Props) => {
  return (
    <div className="flex justify-end p-4 bg-main md:hidden">
      <button type="button" onClick={onMenuClick}>
        <span className="sr-only">Open sidebar</span>

        <Bars3BottomRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </button>
    </div>
  );
};
