import { PlusCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';

import { ButtonWithIcon, Props as ButtonWithIconProps } from '@/components/Button/ButtonWithIcon';

export interface AddEstateButtonProps extends Omit<ButtonWithIconProps, 'icon' | 'iconPosition' | 'variant'> {}

export const AddEstateButton: React.FC<AddEstateButtonProps> = (props) => (
  <ButtonWithIcon variant="white" icon={PlusCircleIcon} iconPosition="after-text" {...props}>
    Adaugă imobil
  </ButtonWithIcon>
);
