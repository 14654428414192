import React from 'react';
import { useDebounce } from 'react-use';

import { Combobox, Props as ComboboxProps } from '@/components/Select/Combobox';

import { SettlementListItem } from '@/domain';
import { Options as LoadSettlementsOptions, useLoadSettlements } from '@/hooks/useLoadSettlements';

export interface Props
  extends Omit<ComboboxProps<SettlementListItem>, 'options' | 'query' | 'onQueryChange'>,
    Partial<Pick<LoadSettlementsOptions, 'county_id'>> {}

export const SettlementsLoadableCombobox = ({ county_id, ...rest }: Props) => {
  const [query, setQuery] = React.useState('');
  const [debouncedQuery, setDebouncedQuery] = React.useState(query);

  useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );
  const settlements = useLoadSettlements({ county_id, name: debouncedQuery });

  return (
    <Combobox
      options={settlements.data ?? []}
      query={query}
      onQueryChange={setQuery}
      {...rest}
      isLoading={settlements.isLoading}
    />
  );
};
