import React from 'react';
import { useDebounce } from 'react-use';

import { Combobox, Props as ComboboxProps } from '@/components/Select/Combobox';

import { StreetListItem } from '@/domain';
import { Options as LoadStreetsOptions, useLoadStreets } from '@/hooks/useLoadStreets';

export interface Props
  extends Omit<ComboboxProps<StreetListItem>, 'options' | 'query' | 'onQueryChange'>,
    Partial<Pick<LoadStreetsOptions, 'city_id'>> {}

export const StreetsLoadableCombobox = ({ city_id, ...rest }: Props) => {
  const [query, setQuery] = React.useState('');
  const [debouncedQuery, setDebouncedQuery] = React.useState(query);

  useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );
  const settlements = useLoadStreets({ city_id, name: debouncedQuery });

  return <Combobox options={settlements.data ?? []} query={query} onQueryChange={setQuery} {...rest} />;
};
