import { FadeInAndOutTransition } from './Transitions/FadeInAndOutTransition';

export interface Props {
  // TODO: Clarify error format
  error: any;
}

export const SubmitError: React.FC<Props> = ({ error }) => {
  return (
    <FadeInAndOutTransition show={!!error} className="text-red-600 flex items-center">
      <span>{error}</span>
    </FadeInAndOutTransition>
  );
};
