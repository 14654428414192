import classNames from 'classnames';
import React, { useState } from 'react';
import { LockClosedIcon, KeyIcon, EnvelopeIcon, EyeIcon, UserIcon, DevicePhoneMobileIcon, BuildingOffice2Icon } from '@heroicons/react/24/solid';
import { withValidation } from '@/components/withValidation';

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  invalid?: boolean;
  forwardIcon?: boolean;
  iconType: string;
  aditionalIcon?: string
}

export const Input = ({label, forwardIcon, iconType, aditionalIcon, className, invalid, ...rest }: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div {...{ className }}>


      {label && (
          <label htmlFor={rest.id} className="block text-sm font-medium text-gray-500 mb-1">
          {label}
        </label>
      )}

      {forwardIcon && iconType === 'BuildingOffice2Icon' && <BuildingOffice2Icon className="h-5 w-5 min-w-[1.25rem] absolute mt-3 ml-3" aria-hidden="true" style={{ color: '#9CA3AF' }}/>}
      {forwardIcon && iconType === 'DevicePhoneMobileIcon' && <DevicePhoneMobileIcon className="h-5 w-5 min-w-[1.25rem] absolute mt-3 ml-3" aria-hidden="true" style={{ color: '#9CA3AF' }}/>}
      {forwardIcon && iconType === 'UserIcon' && <UserIcon className="h-5 w-5 min-w-[1.25rem] absolute mt-3 ml-3" aria-hidden="true" style={{ color: '#9CA3AF' }}/>}
      {forwardIcon && iconType === 'EnvelopeIcon' && <EnvelopeIcon className="h-5 w-5 min-w-[1.25rem] absolute mt-3 ml-3" aria-hidden="true" style={{ color: '#9CA3AF' }}/>}
      {forwardIcon && iconType === 'KeyIcon' && <KeyIcon className="h-5 w-5 min-w-[1.25rem] absolute mt-3 ml-3" aria-hidden="true" style={{ color: '#9CA3AF' }}/>}
      {forwardIcon && aditionalIcon === 'EyeIcon' && <EyeIcon onClick={togglePasswordVisibility} className="h-5 w-5 min-w-[1.25rem] md:ml-[93%] absolute mt-3 ml-[90%]" aria-hidden="true" style={{ color: '#9CA3AF'}}/>}

      <input
       type={passwordVisible ? 'text' : 'password'}
        className={classNames(
          'block w-full min-h-[2.375rem] py-2 px-3 rounded-md border-gray-300 shadow-sm focus:border-main focus:ring-main placeholder:text-gray-400',
          { 'border-red-500 ring-1 ring-red-500 text-red-500': invalid }
        )}
        style={{ paddingLeft: '2.5rem', textAlign: 'left' }}
        onWheel={(event) => (event.target as HTMLElement).blur()}
        {...rest}
      />


    </div>
  );
};

export const InputWithValidationAndSVG = withValidation(Input);
