import { api } from '@/util/networking';

export type SubmitFeedbackResponse = void;

export interface SubmitFeedbackRequestBody {
  is_correct: boolean;
  category_widgets_payload?: object;
  tax_computation_result_payload?: object;
  contact_info?: string;
  additional_note?: string;
  county: number;
  city?: number;
  village?: number;
  street?: number;
  computed_area: number;
}

export const submitFeedback = (options: SubmitFeedbackRequestBody) => {
  return api.post<SubmitFeedbackResponse, SubmitFeedbackRequestBody>('/v1/monitoring/minimum-value-result/', options);
};

export const feedbackAccess = {
  submitFeedback
};
