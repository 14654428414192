import { api } from '@/util/networking';

import { CategoryWidgets } from '@/domain';

export type GetCategoryWidgetsListResponse = CategoryWidgets[];

export interface GetCategoryWidgetsQuery {
  county_id: number;
}

export const getCategoryWidgets = (options: GetCategoryWidgetsQuery) => {
  return api.get<GetCategoryWidgetsListResponse, GetCategoryWidgetsQuery>('/v1/taxes/category-widgets/', options);
};

export const widgetAccess = {
  getCategoryWidgets
};
