import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { Fee } from '@/components/Fee';

export interface Props {}

export const FeePage: React.FC<Props> = () => {

  const [latestPropertyEvaluation] = useLocalStorage('estate-evaluation');

  return <Fee showFillEvaluationValue={!!latestPropertyEvaluation} />;
};
