import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CONTACT_EMAIL_ADDRESS, CONTACT_PHONE_NUMBER, routes } from '@/constants';
import { RegisterForm } from '@/pages/Register/RegisterForm';
import { ButtonWithIcon } from '@/components/Button/ButtonWithIcon';
import { BanknotesIcon } from '@heroicons/react/20/solid';

export interface Props {
}

export const RegisterPage: React.FC<Props> = () => {

  const navigate = useNavigate();

  return (
    <div className="md:pt-[5vh] lg:pt-[10vh] sm:pt-[5vh] h-[150vh] w-full bg-[#314B3C] bg-cover">
      <div className='flex flex-col items-center sm:pb-10 pt-6 pb-6 sm:pt-10 md:pt-12 md:relative md:pb-12 w-[80vw]
       md:w-1/2 ml-[10vw] md:ml-[28vw] bg-[#F3F4F6] rounded-3xl'>
        <div className="text-gray-700 text-3xl font-semibold leading-9 font-poppins break-words md:pb-10 pb-7">
          Creează-ți contul
        </div>
        <div className="flex justify-center text-center items-center w-full px-6 md:px-16">
          <RegisterForm id='register-page'/>
        </div>
        <div className="flex justify-around flex-row mt-5 text-center items-center">
          <span className="text-gray-500 text-base font-normal leading-5 mr-1">Ai deja un cont?</span>
          <span className="text-green-600 text-base font-semibold leading-5 cursor-pointer"
                onClick={() => navigate(routes.login)}>
            Autentifică-te acum
          </span>
        </div>
        <div className="flex justify-center text-center items-center w-full px-6 md:px-16 mt-4">
          <ButtonWithIcon
            icon={BanknotesIcon}
            variant="white"
            className="w-full"
            onClick={() => navigate(routes.app)}
          >
            Acces fără cont, limitat
        </ButtonWithIcon>
        </div>
      <div className="text-center md:w-[75%] sm:w-[80%] pt-5"
          style={{
            fontSize: '14px',
          }}>
          Dacă întâmpinați probleme tehnice, ne puteți contacta la 
          <a className="text-green-600 text-base font-semibold"
          href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>{` ${CONTACT_EMAIL_ADDRESS} `}</a>
          sau la 
          <a className="text-green-600 text-base font-semibold"
          href={`tel:${CONTACT_PHONE_NUMBER}`}>{` ${CONTACT_PHONE_NUMBER}`}</a>
        </div>
      </div>
    </div>
    );
}
