import useSWR from 'swr';

import { GetSettlementListQuery, placesAccess } from '@/access';

export type Options = GetSettlementListQuery;

export const useLoadSettlements = (options: Partial<Options>) => {
  return useSWR(
    options.county_id && options.name ? { ...options, key: 'getSettlementsList' } : undefined,
    ({ county_id, name }) => {
      if (!county_id || !name) return;

      return placesAccess.getSettlementsList({ county_id, name });
    },

    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
};
