import classNames from 'classnames';

import { ReactComponent as LogoAsset } from './logo.svg';

type Color = 'white' | 'green';
type TextType = 'medium' | 'xl';

const colors: Record<Color, { text: string; fill: string }> = {
  white: {
    text: 'text-white',
    fill: 'white'
  },
  green: {
    text: 'text-black',
    fill: '#527A62'
  }
};

export interface Props extends React.SVGProps<SVGSVGElement> {
  showText?: boolean;
  color?: Color;
  logoClassName?: string;
  textClassName?: string;
}

export const Logo = ({
  className,
  color = 'white',
  logoClassName,
  textClassName,
  showText = false,
  ...rest
}: Props) => (
  <span className={classNames('flex items-center', className)}>
    <LogoAsset className={classNames('pr-2', logoClassName)} fill={colors[color].fill} {...rest} />

    {showText && <span className={classNames(colors[color].text, textClassName)}>Notario</span>}
  </span>
);
