import React from 'react';
import { useAsyncFn, useDebounce } from 'react-use';

import { placesAccess } from '@/access';
import { CountyListItem, SettlementListItem, SettlementSubdivision, StreetListItem } from '@/domain';

interface Options {
  county?: CountyListItem;
  settlement?: SettlementListItem;
  street?: StreetListItem;
  streetNumber?: string;
}

export const useDetermineArea = ({ county, settlement, street, streetNumber }: Options) => {
  const [state, fetch] = useAsyncFn(async () => {
    if (!settlement) return;

    const [type, id] = settlement.id?.split('-') ?? [];

    if (!street && type === 'city') return;

    const options = type === 'city' ? { street_id: street?.id } : { village_id: id };

    return placesAccess.getAreasList(options);
  }, [settlement?.id, street?.id]);

  useDebounce(
    () => {
      const shouldDetermineArea = !!(
        county &&
        settlement &&
        (settlement.subdivisions.includes(SettlementSubdivision.Street) ? street && streetNumber : true)
      );

      if (shouldDetermineArea) fetch();
    },
    500,
    [county?.id, settlement?.id, street?.id, streetNumber]
  );

  return React.useMemo(
    () => ({
      loading: state.loading,
      value: state.value?.[0]
    }),
    [state.loading, state.value?.[0]]
  );
};
