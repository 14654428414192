import classNames from 'classnames';

import { Logo } from '@/components/Logo';
import { PaddedComponent } from '@/components/PaddedComponent';

import { links } from '@/constants';

import { ReactComponent as LinkedInLogo } from './linkedin-logo.svg';

const social = [
  {
    href: 'https://www.linkedin.com/company/notario-romania',
    icon: LinkedInLogo
  }
];

export interface Props {}

export const Footer: React.FC<Props> = () => {
  return (
    <footer className="text-white">
      <PaddedComponent className="grid grid-cols-2 grid-rows-2 pt-4 bg-main md:grid-cols-4 md:grid-rows-1 md:pt-9 md:pb-12">
        <Section className="row-start-2 md:row-start-1" title="Social media">
          <div>
            {social.map(({ icon: Icon, href }, index) => (
              <a key={index} target="_blank" rel="noopener noreferrer" {...{ href }}>
                <Icon className="w-5 h-5 md:w-6 md:h-6" />
              </a>
            ))}
          </div>
        </Section>

        <Section className="col-span-2 items-center md:col-span-2 md:row-start-1">
          <Logo
            logoClassName="w-14 h-14 md:w-32 md:h-32 md:pr-6"
            textClassName="font-semibold text-xl md:text-3xl"
            showText
          />

          <p className="text-center text-xs text-main-light mt-7 md:text-sm w-">
            Eficientizăm munca notarilor, pas cu pas
          </p>
        </Section>

        <Section className="row-start-2 md:row-start-1" title="Pagina principală">
          <div className="flex flex-col space-y-2 text-xs font-medium">
            {links.map(({ href, name }) => (
              <a key={href} {...{ href }}>
                {name}
              </a>
            ))}

            {/* <div>
              <ButtonWithIcon
                icon={ArrowRightIcon}
                variant="white"
                className="text-xs inline-block h-8"
                type="button"
                onClick={() => {
                  navigate(routes.app.main);
                }}
              >
                Deschide aplicația
              </ButtonWithIcon>
            </div> */}
          </div>
        </Section>
      </PaddedComponent>

      <PaddedComponent className="flex justify-center bg-main-dark py-3.5 md:py-5">
        <small className="text-xs text-center text-main-light">
          Copyright © {new Date().getFullYear()} Notario Toate drepturile rezervate.
        </small>
      </PaddedComponent>
    </footer>
  );
};

interface FooterSectionProps {
  title?: string;
  className?: string;
}

const Section = ({ title, className, children }: React.PropsWithChildren<FooterSectionProps>) => (
  <div className={classNames('flex flex-col p-4', className)}>
    {title && <span className="text-sm mb-3 font-medium md:text-xl ">{title}</span>}

    {children}
  </div>
);
